import React, { Fragment } from 'react'
import { isEmpty } from 'lodash'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableCell
} from '@mui/material'

import { unitLabel } from '../../../common/utils/buildings'
import usePagination from '../../../common/hooks/usePagination'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'
import { SENSOR_EVENTS_ALERT_GENERATED, SENSOR_EVENTS_SENSOR_MUTED } from '../../../common/constants/tooltips'
import useSensorEventsTable from './useSensorEventsTable'

import TooltipIcon from '../../atoms/TooltopIcon/TooltipIcon'
import SensorEventListRow from '../../molecules/SensorEventListRow'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'

const SensorEventsTable = ({
  sensor,
  building,
  userType,
  numRowsPerPage,
  tableContainerSx = {},
  tableSx = {},
  showNoRecordsMessage = true
}) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions } = usePagination({
    numRowsPerPage
  })

  const { loading, sensorEvents, metadata, hasTemperature, hasPowerEvent, isSuperadmin } =
    useSensorEventsTable({
      sensorId: sensor?.id,
      page,
      rowsPerPage,
      userType
    })

  if (loading) return <React.Fragment></React.Fragment>
  const numberOfColumns = 10 // used for "No Sensor Events Recorded" message
  return (
    <TableContainer sx={tableContainerSx}>
      <Table sx={tableSx}>
        <TableHead>
          <TableRow>
            <StyledTableCell align='left'>Sensor {unitLabel(building)}</StyledTableCell>
            <StyledTableCell align='left'>Sensor Location</StyledTableCell>
            <StyledTableCell align='left'>Event Type</StyledTableCell>
            <StyledTableCell align='left'>Event Time</StyledTableCell>
            {hasTemperature && <StyledTableCell align='left'>Temperature °C</StyledTableCell>}
            {hasPowerEvent && <StyledTableCell align='left'>Power Source</StyledTableCell>}
            <StyledTableCell align='left'>
              Alert Generated
              <TooltipIcon title={SENSOR_EVENTS_ALERT_GENERATED} />
            </StyledTableCell>
            <StyledTableCell align='left'>
              Sensor Muted
              <TooltipIcon title={SENSOR_EVENTS_SENSOR_MUTED} />
            </StyledTableCell>
            {isSuperadmin && <StyledTableCell align='left'>Hardware ID</StyledTableCell>}
            {isSuperadmin && <StyledTableCell align='left'>Message ID</StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {showNoRecordsMessage && isEmpty(sensorEvents) && (
            <StyledTableRow hover>
              <TableCell align='center' sx={{ verticalAlign: 'top' }} colSpan={numberOfColumns}>
                <h4>No Sensor Events Recorded</h4>
              </TableCell>
            </StyledTableRow>
          )}
          {sensorEvents.map((sensorEvent) => (
            <Fragment key={sensorEvent.id}>
              <SensorEventListRow
                sensorEvent={sensorEvent}
                hasTemperature={hasTemperature}
                hasPowerEvent={hasPowerEvent}
                isSuperadmin={isSuperadmin}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        colSpan={4}
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={metadata?.totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default SensorEventsTable
