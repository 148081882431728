import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAlertDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  eventFilter?: Types.InputMaybe<Types.Scalars['String']>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchAlertDataQuery = { __typename?: 'Query', fetchAlertData: unknown };

export type FetchIaqDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
  orderBy?: Types.InputMaybe<Types.Scalars['String']>;
  order?: Types.InputMaybe<Types.Scalars['String']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchIaqDataQuery = { __typename?: 'Query', fetchIaqData: unknown };

export type FetchIaqChartDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchIaqChartDataQuery = { __typename?: 'Query', fetchIaqChartData: unknown };

export type FetchIaqOutliersQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
  iaqAttribute: Types.Scalars['String'];
  lowHigh: Types.Scalars['String'];
}>;


export type FetchIaqOutliersQuery = { __typename?: 'Query', fetchIaqOutliers: unknown };

export type FetchOccupancyDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchOccupancyDataQuery = { __typename?: 'Query', fetchOccupancyData: unknown };


export const FetchAlertDataDocument = gql`
    query fetchAlertData($filters: WidgetFiltersType, $eventFilter: String, $reportToken: String) {
  fetchAlertData(
    filters: $filters
    eventFilter: $eventFilter
    reportToken: $reportToken
  )
}
    `;

/**
 * __useFetchAlertDataQuery__
 *
 * To run a query within a React component, call `useFetchAlertDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAlertDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAlertDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      eventFilter: // value for 'eventFilter'
 *      reportToken: // value for 'reportToken'
 *   },
 * });
 */
export function useFetchAlertDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchAlertDataQuery, FetchAlertDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAlertDataQuery, FetchAlertDataQueryVariables>(FetchAlertDataDocument, options);
      }
export function useFetchAlertDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAlertDataQuery, FetchAlertDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAlertDataQuery, FetchAlertDataQueryVariables>(FetchAlertDataDocument, options);
        }
export type FetchAlertDataQueryHookResult = ReturnType<typeof useFetchAlertDataQuery>;
export type FetchAlertDataLazyQueryHookResult = ReturnType<typeof useFetchAlertDataLazyQuery>;
export type FetchAlertDataQueryResult = Apollo.QueryResult<FetchAlertDataQuery, FetchAlertDataQueryVariables>;
export const FetchIaqDataDocument = gql`
    query fetchIaqData($filters: WidgetFiltersType, $reportToken: String, $orderBy: String, $order: String, $pagination: PaginationType) {
  fetchIaqData(
    filters: $filters
    reportToken: $reportToken
    orderBy: $orderBy
    order: $order
    pagination: $pagination
  )
}
    `;

/**
 * __useFetchIaqDataQuery__
 *
 * To run a query within a React component, call `useFetchIaqDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIaqDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIaqDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchIaqDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchIaqDataQuery, FetchIaqDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchIaqDataQuery, FetchIaqDataQueryVariables>(FetchIaqDataDocument, options);
      }
export function useFetchIaqDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchIaqDataQuery, FetchIaqDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchIaqDataQuery, FetchIaqDataQueryVariables>(FetchIaqDataDocument, options);
        }
export type FetchIaqDataQueryHookResult = ReturnType<typeof useFetchIaqDataQuery>;
export type FetchIaqDataLazyQueryHookResult = ReturnType<typeof useFetchIaqDataLazyQuery>;
export type FetchIaqDataQueryResult = Apollo.QueryResult<FetchIaqDataQuery, FetchIaqDataQueryVariables>;
export const FetchIaqChartDataDocument = gql`
    query fetchIaqChartData($filters: WidgetFiltersType, $reportToken: String) {
  fetchIaqChartData(filters: $filters, reportToken: $reportToken)
}
    `;

/**
 * __useFetchIaqChartDataQuery__
 *
 * To run a query within a React component, call `useFetchIaqChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIaqChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIaqChartDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *   },
 * });
 */
export function useFetchIaqChartDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchIaqChartDataQuery, FetchIaqChartDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchIaqChartDataQuery, FetchIaqChartDataQueryVariables>(FetchIaqChartDataDocument, options);
      }
export function useFetchIaqChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchIaqChartDataQuery, FetchIaqChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchIaqChartDataQuery, FetchIaqChartDataQueryVariables>(FetchIaqChartDataDocument, options);
        }
export type FetchIaqChartDataQueryHookResult = ReturnType<typeof useFetchIaqChartDataQuery>;
export type FetchIaqChartDataLazyQueryHookResult = ReturnType<typeof useFetchIaqChartDataLazyQuery>;
export type FetchIaqChartDataQueryResult = Apollo.QueryResult<FetchIaqChartDataQuery, FetchIaqChartDataQueryVariables>;
export const FetchIaqOutliersDocument = gql`
    query fetchIaqOutliers($filters: WidgetFiltersType, $reportToken: String, $iaqAttribute: String!, $lowHigh: String!) {
  fetchIaqOutliers(
    filters: $filters
    reportToken: $reportToken
    iaqAttribute: $iaqAttribute
    lowHigh: $lowHigh
  )
}
    `;

/**
 * __useFetchIaqOutliersQuery__
 *
 * To run a query within a React component, call `useFetchIaqOutliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIaqOutliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIaqOutliersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *      iaqAttribute: // value for 'iaqAttribute'
 *      lowHigh: // value for 'lowHigh'
 *   },
 * });
 */
export function useFetchIaqOutliersQuery(baseOptions: Apollo.QueryHookOptions<FetchIaqOutliersQuery, FetchIaqOutliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchIaqOutliersQuery, FetchIaqOutliersQueryVariables>(FetchIaqOutliersDocument, options);
      }
export function useFetchIaqOutliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchIaqOutliersQuery, FetchIaqOutliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchIaqOutliersQuery, FetchIaqOutliersQueryVariables>(FetchIaqOutliersDocument, options);
        }
export type FetchIaqOutliersQueryHookResult = ReturnType<typeof useFetchIaqOutliersQuery>;
export type FetchIaqOutliersLazyQueryHookResult = ReturnType<typeof useFetchIaqOutliersLazyQuery>;
export type FetchIaqOutliersQueryResult = Apollo.QueryResult<FetchIaqOutliersQuery, FetchIaqOutliersQueryVariables>;
export const FetchOccupancyDataDocument = gql`
    query fetchOccupancyData($filters: WidgetFiltersType, $reportToken: String) {
  fetchOccupancyData(filters: $filters, reportToken: $reportToken)
}
    `;

/**
 * __useFetchOccupancyDataQuery__
 *
 * To run a query within a React component, call `useFetchOccupancyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOccupancyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOccupancyDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *   },
 * });
 */
export function useFetchOccupancyDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchOccupancyDataQuery, FetchOccupancyDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOccupancyDataQuery, FetchOccupancyDataQueryVariables>(FetchOccupancyDataDocument, options);
      }
export function useFetchOccupancyDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOccupancyDataQuery, FetchOccupancyDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOccupancyDataQuery, FetchOccupancyDataQueryVariables>(FetchOccupancyDataDocument, options);
        }
export type FetchOccupancyDataQueryHookResult = ReturnType<typeof useFetchOccupancyDataQuery>;
export type FetchOccupancyDataLazyQueryHookResult = ReturnType<typeof useFetchOccupancyDataLazyQuery>;
export type FetchOccupancyDataQueryResult = Apollo.QueryResult<FetchOccupancyDataQuery, FetchOccupancyDataQueryVariables>;