import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: unknown;
  /** An ISO 8601-encoded date */
  ISO8601Date: unknown;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: unknown;
  /** Represents untyped JSON */
  JSON: unknown;
};

export type Alert = {
  __typename?: 'Alert';
  acknowledgedUser?: Maybe<User>;
  acknowledgedUserId?: Maybe<Scalars['ID']>;
  alertType: AlertType;
  createdAt: Scalars['ISO8601DateTime'];
  feedback?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sensorEvent: SensorEvent;
  sensorEventId: Scalars['ID'];
  severity?: Maybe<Severity>;
  unit: Unit;
  unitId: Scalars['ID'];
  userAlerts: Array<UserAlert>;
  usersCount: Scalars['Int'];
};

export type AlertCollection = {
  __typename?: 'AlertCollection';
  collection: Array<Alert>;
  metadata: CollectionMetadata;
};

/** AlertType of Alert */
export enum AlertType {
  /** door_open */
  DoorOpen = 'door_open',
  /** high_humidity */
  HighHumidity = 'high_humidity',
  /** high_temperature */
  HighTemperature = 'high_temperature',
  /** low_humidity */
  LowHumidity = 'low_humidity',
  /** low_temperature */
  LowTemperature = 'low_temperature',
  /** power */
  Power = 'power',
  /** water */
  Water = 'water'
}

export type Bar = {
  __typename?: 'Bar';
  datasets: Array<BarDataset>;
};

export type BarDataset = {
  __typename?: 'BarDataset';
  data: Scalars['BigInt'];
  label: Scalars['String'];
};

export type Building = {
  __typename?: 'Building';
  activeSensorsCount: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  buildingType: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  doorAlertMessage?: Maybe<Scalars['String']>;
  floors: Scalars['Int'];
  humidityAverage?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  location: Location;
  locationId: Scalars['ID'];
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  powerSource?: Maybe<Scalars['String']>;
  stateProvince: Scalars['String'];
  temperatureAverage?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
  waterAlertMessage?: Maybe<Scalars['String']>;
};

export type BuildingCollection = {
  __typename?: 'BuildingCollection';
  collection: Array<Building>;
  metadata: CollectionMetadata;
};

export type CleaningRequest = {
  __typename?: 'CleaningRequest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  initiatedInternally: Scalars['Boolean'];
  resolvedAt?: Maybe<Scalars['ISO8601DateTime']>;
  resolvedByUser?: Maybe<User>;
  resolvedByUserId?: Maybe<Scalars['ID']>;
  sensorEvent?: Maybe<SensorEvent>;
  sensorEventId?: Maybe<Scalars['ID']>;
  unit: Unit;
  unitId: Scalars['ID'];
};

export type CleaningRequestCollection = {
  __typename?: 'CleaningRequestCollection';
  collection: Array<CleaningRequest>;
  metadata: CollectionMetadata;
};

export type CollectionMetadata = {
  __typename?: 'CollectionMetadata';
  currentPage: Scalars['Int'];
  limitValue: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  featureConfig?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of CreateDashboard */
export type CreateDashboardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated input type of CreateDashboardWidget */
export type CreateDashboardWidgetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dashboardId: Scalars['ID'];
  type: DashboardWidgetType;
};

/** Autogenerated input type of CreateGateway */
export type CreateGatewayInput = {
  buildingId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  hardwareId?: InputMaybe<Scalars['String']>;
  inUse?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['ID']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateUserReport */
export type CreateUserReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  criteria: Scalars['JSON'];
  name: Scalars['String'];
  recipients?: InputMaybe<Array<Scalars['String']>>;
  reportInterval: Scalars['String'];
  startDate: Scalars['ISO8601Date'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  company: Company;
  companyId: Scalars['ID'];
  createdAt: Scalars['ISO8601DateTime'];
  filters?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  selectedBuildings: Array<Scalars['String']>;
  selectedSensors: Array<Scalars['String']>;
  selectedUnits: Array<Scalars['String']>;
  token: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  widgets: Array<DashboardWidget>;
};

export type DashboardCollection = {
  __typename?: 'DashboardCollection';
  collection: Array<Dashboard>;
  metadata: CollectionMetadata;
};

export type DashboardWidget = {
  __typename?: 'DashboardWidget';
  createdAt: Scalars['ISO8601DateTime'];
  dashboard: Dashboard;
  dashboardId: Scalars['ID'];
  id: Scalars['ID'];
  position: Scalars['Int'];
  type: DashboardWidgetType;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type DashboardWidgetCollection = {
  __typename?: 'DashboardWidgetCollection';
  collection: Array<DashboardWidget>;
  metadata: CollectionMetadata;
};

/** DashboardWidgetType of DashboardWidget */
export enum DashboardWidgetType {
  /** Active Sensors severity. */
  ActiveSensors = 'active_sensors',
  /** Average Co2 Gauges severity. */
  AverageCo2Gauges = 'average_co2_gauges',
  /** Average Humidity Gauges severity. */
  AverageHumidityGauges = 'average_humidity_gauges',
  /** Average Pressure Gauges severity. */
  AveragePressureGauges = 'average_pressure_gauges',
  /** Average Temperature Gauges severity. */
  AverageTemperatureGauges = 'average_temperature_gauges',
  /** Cleaning Request Lines severity. */
  CleaningRequestLines = 'cleaning_request_lines',
  /** Cleaning Request Polars severity. */
  CleaningRequestPolars = 'cleaning_request_polars',
  /** Cleaning Requests severity. */
  CleaningRequests = 'cleaning_requests',
  /** Co2 Activity Lines severity. */
  Co2ActivityLines = 'co2_activity_lines',
  /** Co2 Activity Polars severity. */
  Co2ActivityPolars = 'co2_activity_polars',
  /** Door Activities severity. */
  DoorActivities = 'door_activities',
  /** Door Activity Bars severity. */
  DoorActivityBars = 'door_activity_bars',
  /** Door Activity Polars severity. */
  DoorActivityPolars = 'door_activity_polars',
  /** Exterior Temperatures severity. */
  ExteriorTemperatures = 'exterior_temperatures',
  /** Humidity Activity Lines severity. */
  HumidityActivityLines = 'humidity_activity_lines',
  /** Power Statuses severity. */
  PowerStatuses = 'power_statuses',
  /** Pressure Activity Lines severity. */
  PressureActivityLines = 'pressure_activity_lines',
  /** Temperature Activities severity. */
  TemperatureActivities = 'temperature_activities',
  /** Temperature Polars severity. */
  TemperaturePolars = 'temperature_polars',
  /** Water Alerts severity. */
  WaterAlerts = 'water_alerts'
}

/** Autogenerated input type of DeleteDashboard */
export type DeleteDashboardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteDashboardWidget */
export type DeleteDashboardWidgetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteGateway */
export type DeleteGatewayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** EventType of SensorEvent */
export enum EventType {
  /** door_closed */
  DoorClosed = 'door_closed',
  /** door_open */
  DoorOpen = 'door_open',
  /** high_humidity */
  HighHumidity = 'high_humidity',
  /** high_temperature */
  HighTemperature = 'high_temperature',
  /** low_humidity */
  LowHumidity = 'low_humidity',
  /** low_temperature */
  LowTemperature = 'low_temperature',
  /** power */
  Power = 'power',
  /** sound */
  Sound = 'sound',
  /** supervisory */
  Supervisory = 'supervisory',
  /** water */
  Water = 'water'
}

export type Filters = {
  buildingHumidityAverageTimestamp?: InputMaybe<Scalars['ISO8601DateTime']>;
  buildingTemperatureAverageTimestamp?: InputMaybe<Scalars['ISO8601DateTime']>;
  unitId?: InputMaybe<Scalars['ID']>;
};

export type Gateway = {
  __typename?: 'Gateway';
  buildingId: Scalars['ID'];
  createdAt: Scalars['ISO8601DateTime'];
  hardwareId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inUse: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  serialNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type GatewayCollection = {
  __typename?: 'GatewayCollection';
  collection: Array<Gateway>;
  metadata: CollectionMetadata;
};

export type LineDataset = {
  __typename?: 'LineDataset';
  data: Array<Scalars['BigInt']>;
  label: Scalars['String'];
};

export type LineLabel = {
  __typename?: 'LineLabel';
  from: Scalars['BigInt'];
  to: Scalars['BigInt'];
};

export type Lines = {
  __typename?: 'Lines';
  datasets: Array<LineDataset>;
  labels: Array<LineLabel>;
};

export type Location = {
  __typename?: 'Location';
  actualWeather?: Maybe<LocationWeather>;
  buildings: Array<Building>;
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['ID'];
  locationWeathers: Array<LocationWeather>;
  stateProvince: Scalars['String'];
};

export type LocationWeather = {
  __typename?: 'LocationWeather';
  condition: Scalars['String'];
  daytime: Scalars['Boolean'];
  humidity: Scalars['Int'];
  id: Scalars['ID'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['Int']>;
  pressure: Scalars['Int'];
  recordedAt: Scalars['ISO8601DateTime'];
  temperature: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createDashboard: Dashboard;
  createDashboardWidget: DashboardWidget;
  createGateway: Gateway;
  createUserReport: UserReport;
  deleteDashboard: Dashboard;
  deleteDashboardWidget: DashboardWidget;
  deleteGateway: Gateway;
  updateAlert: Alert;
  updateDashboard: Dashboard;
  updateDashboardWidgetPosition: Array<DashboardWidget>;
  updateGateway: Gateway;
  updateUserReport: UserReport;
};


export type MutationCreateDashboardArgs = {
  input: CreateDashboardInput;
};


export type MutationCreateDashboardWidgetArgs = {
  input: CreateDashboardWidgetInput;
};


export type MutationCreateGatewayArgs = {
  input: CreateGatewayInput;
};


export type MutationCreateUserReportArgs = {
  input: CreateUserReportInput;
};


export type MutationDeleteDashboardArgs = {
  input: DeleteDashboardInput;
};


export type MutationDeleteDashboardWidgetArgs = {
  input: DeleteDashboardWidgetInput;
};


export type MutationDeleteGatewayArgs = {
  input: DeleteGatewayInput;
};


export type MutationUpdateAlertArgs = {
  input: UpdateAlertInput;
};


export type MutationUpdateDashboardArgs = {
  input: UpdateDashboardInput;
};


export type MutationUpdateDashboardWidgetPositionArgs = {
  input: UpdateDashboardWidgetPositionInput;
};


export type MutationUpdateGatewayArgs = {
  input: UpdateGatewayInput;
};


export type MutationUpdateUserReportArgs = {
  input: UpdateUserReportInput;
};

/** Basic Pagination Input */
export type PaginationType = {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
};

export type Project = {
  __typename?: 'Project';
  buildingId: Scalars['ID'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  installDate: Scalars['ISO8601Date'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProjectCollection = {
  __typename?: 'ProjectCollection';
  collection: Array<Project>;
  metadata: CollectionMetadata;
};

export type Query = {
  __typename?: 'Query';
  fetchAlertData: Scalars['JSON'];
  fetchAlerts: AlertCollection;
  fetchBuildings: Array<Building>;
  fetchCompanies: Array<Company>;
  fetchDashboardWidgets: DashboardWidgetCollection;
  fetchDashboards: DashboardCollection;
  fetchGateways: GatewayCollection;
  fetchIaqChartData: Scalars['JSON'];
  fetchIaqData: Scalars['JSON'];
  fetchIaqOutliers: Scalars['JSON'];
  fetchOccupancyData: Scalars['JSON'];
  fetchProjects: ProjectCollection;
  fetchSensorEvents: SensorEventCollection;
  fetchSensors: SensorCollection;
  fetchSensorsCollection: SensorCollection;
  fetchUnits: Array<Unit>;
  fetchUserBuildings: Array<UserBuilding>;
  fetchUserReports: UserReportCollection;
  fetchUsers: Array<User>;
  getBuilding: Building;
  getCurrentUser: User;
  getDashboard: Dashboard;
  getGateway: Gateway;
  getReport: Report;
  getSensor: Sensor;
  getUserAlert: UserAlert;
  widgetActiveSensors: BuildingCollection;
  widgetAverageCo2Gauges: Scalars['Int'];
  widgetAverageHumidityGauges: Scalars['Int'];
  widgetAveragePressureGauges: Scalars['Int'];
  widgetAverageTemperatureGauges?: Maybe<Scalars['Int']>;
  widgetCleaningRequestLines: Lines;
  widgetCleaningRequestPolars?: Maybe<Bar>;
  widgetCleaningRequests: CleaningRequestCollection;
  widgetCo2ActivityLines: Lines;
  widgetCo2ActivityPolars?: Maybe<Bar>;
  widgetDoorActivities: SensorEventCollection;
  widgetDoorActivityBars?: Maybe<Bar>;
  widgetExteriorTemperatures: BuildingCollection;
  widgetHumidityActivityLines: Lines;
  widgetPowerStatuses: BuildingCollection;
  widgetPressureActivityLines: Lines;
  widgetTemperatureActivities: Lines;
  widgetTemperaturePolars?: Maybe<Bar>;
  widgetWaterAlerts: SensorEventCollection;
};


export type QueryFetchAlertDataArgs = {
  eventFilter?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<WidgetFiltersType>;
  reportToken?: InputMaybe<Scalars['String']>;
};


export type QueryFetchAlertsArgs = {
  buildingId?: InputMaybe<Scalars['ID']>;
  eventFilter?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationType>;
  query?: InputMaybe<Scalars['String']>;
  reportFilters?: InputMaybe<WidgetFiltersType>;
  reportToken?: InputMaybe<Scalars['String']>;
  sensorId?: InputMaybe<Scalars['ID']>;
};


export type QueryFetchBuildingsArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  pagination?: InputMaybe<PaginationType>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryFetchCompaniesArgs = {
  pagination?: InputMaybe<PaginationType>;
};


export type QueryFetchDashboardWidgetsArgs = {
  pagination?: InputMaybe<PaginationType>;
  token: Scalars['String'];
};


export type QueryFetchDashboardsArgs = {
  pagination?: InputMaybe<PaginationType>;
};


export type QueryFetchGatewaysArgs = {
  buildingId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationType>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryFetchIaqChartDataArgs = {
  breakpointsCount?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<WidgetFiltersType>;
  linesCount?: InputMaybe<Scalars['Int']>;
  reportToken?: InputMaybe<Scalars['String']>;
};


export type QueryFetchIaqDataArgs = {
  filters?: InputMaybe<WidgetFiltersType>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationType>;
  reportToken?: InputMaybe<Scalars['String']>;
};


export type QueryFetchIaqOutliersArgs = {
  filters?: InputMaybe<WidgetFiltersType>;
  iaqAttribute: Scalars['String'];
  lowHigh: Scalars['String'];
  reportToken?: InputMaybe<Scalars['String']>;
};


export type QueryFetchOccupancyDataArgs = {
  filters?: InputMaybe<WidgetFiltersType>;
  reportToken?: InputMaybe<Scalars['String']>;
};


export type QueryFetchProjectsArgs = {
  buildingId?: InputMaybe<Scalars['ID']>;
};


export type QueryFetchSensorEventsArgs = {
  pagination?: InputMaybe<PaginationType>;
  sensorId?: InputMaybe<Scalars['ID']>;
};


export type QueryFetchSensorsArgs = {
  buildingId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationType>;
  unitId?: InputMaybe<Scalars['ID']>;
};


export type QueryFetchSensorsCollectionArgs = {
  buildingIds?: InputMaybe<Array<Scalars['ID']>>;
  companyId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  pagination?: InputMaybe<PaginationType>;
  query?: InputMaybe<Scalars['String']>;
  unitIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryFetchUnitsArgs = {
  buildingIds?: InputMaybe<Array<Scalars['ID']>>;
  companyId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  pagination?: InputMaybe<PaginationType>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryFetchUserBuildingsArgs = {
  filters?: InputMaybe<Filters>;
  pagination?: InputMaybe<PaginationType>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryFetchUserReportsArgs = {
  pagination?: InputMaybe<PaginationType>;
};


export type QueryGetBuildingArgs = {
  id: Scalars['ID'];
};


export type QueryGetDashboardArgs = {
  token: Scalars['String'];
};


export type QueryGetGatewayArgs = {
  id: Scalars['ID'];
};


export type QueryGetReportArgs = {
  token: Scalars['String'];
};


export type QueryGetSensorArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserAlertArgs = {
  shortcode: Scalars['String'];
};


export type QueryWidgetActiveSensorsArgs = {
  pagination?: InputMaybe<PaginationType>;
  sorting?: InputMaybe<SortingType>;
  token: Scalars['String'];
};


export type QueryWidgetAverageCo2GaugesArgs = {
  token: Scalars['String'];
};


export type QueryWidgetAverageHumidityGaugesArgs = {
  token: Scalars['String'];
};


export type QueryWidgetAveragePressureGaugesArgs = {
  token: Scalars['String'];
};


export type QueryWidgetAverageTemperatureGaugesArgs = {
  token: Scalars['String'];
};


export type QueryWidgetCleaningRequestLinesArgs = {
  breakpointsCount?: InputMaybe<Scalars['Int']>;
  linesCount?: InputMaybe<Scalars['Int']>;
  token: Scalars['String'];
};


export type QueryWidgetCleaningRequestPolarsArgs = {
  token: Scalars['String'];
};


export type QueryWidgetCleaningRequestsArgs = {
  pagination?: InputMaybe<PaginationType>;
  sorting?: InputMaybe<SortingType>;
  token: Scalars['String'];
};


export type QueryWidgetCo2ActivityLinesArgs = {
  breakpointsCount?: InputMaybe<Scalars['Int']>;
  linesCount?: InputMaybe<Scalars['Int']>;
  token: Scalars['String'];
};


export type QueryWidgetCo2ActivityPolarsArgs = {
  token: Scalars['String'];
};


export type QueryWidgetDoorActivitiesArgs = {
  pagination?: InputMaybe<PaginationType>;
  sorting?: InputMaybe<SortingType>;
  token: Scalars['String'];
};


export type QueryWidgetDoorActivityBarsArgs = {
  token: Scalars['String'];
};


export type QueryWidgetExteriorTemperaturesArgs = {
  pagination?: InputMaybe<PaginationType>;
  sorting?: InputMaybe<SortingType>;
  token: Scalars['String'];
};


export type QueryWidgetHumidityActivityLinesArgs = {
  breakpointsCount?: InputMaybe<Scalars['Int']>;
  linesCount?: InputMaybe<Scalars['Int']>;
  token: Scalars['String'];
};


export type QueryWidgetPowerStatusesArgs = {
  pagination?: InputMaybe<PaginationType>;
  sorting?: InputMaybe<SortingType>;
  token: Scalars['String'];
};


export type QueryWidgetPressureActivityLinesArgs = {
  breakpointsCount?: InputMaybe<Scalars['Int']>;
  linesCount?: InputMaybe<Scalars['Int']>;
  token: Scalars['String'];
};


export type QueryWidgetTemperatureActivitiesArgs = {
  breakpointsCount?: InputMaybe<Scalars['Int']>;
  linesCount?: InputMaybe<Scalars['Int']>;
  token: Scalars['String'];
};


export type QueryWidgetTemperaturePolarsArgs = {
  token: Scalars['String'];
};


export type QueryWidgetWaterAlertsArgs = {
  pagination?: InputMaybe<PaginationType>;
  sorting?: InputMaybe<SortingType>;
  token: Scalars['String'];
};

export type Report = {
  __typename?: 'Report';
  createdAt: Scalars['ISO8601DateTime'];
  criteria?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  selectedBuildings: Array<Scalars['String']>;
  selectedSensors: Array<Scalars['String']>;
  selectedUnits: Array<Scalars['String']>;
  sentTo?: Maybe<Array<Scalars['String']>>;
  token: Scalars['String'];
  userReport: UserReport;
};

/** ReportInterval of UserReport */
export enum ReportInterval {
  /** Month interval */
  Month = 'month',
  /** Quarter interval */
  Quarter = 'quarter',
  /** Week interval */
  Week = 'week',
  /** Year interval */
  Year = 'year'
}

export type Sensor = {
  __typename?: 'Sensor';
  building?: Maybe<Building>;
  buildingId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  deviceName?: Maybe<Scalars['String']>;
  doorAlertDays?: Maybe<Scalars['String']>;
  doorAlertEndTime?: Maybe<Scalars['String']>;
  doorAlertHoursEnabled: Scalars['Boolean'];
  doorAlertStartTime?: Maybe<Scalars['String']>;
  doorAlertWeekendHolidays?: Maybe<Scalars['Boolean']>;
  enableAlerts: Scalars['Boolean'];
  hardwareId?: Maybe<Scalars['String']>;
  highHumidityThreshold?: Maybe<Scalars['Int']>;
  highTemperatureThreshold?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  inUse: Scalars['Boolean'];
  isMuted?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  lowHumidityThreshold?: Maybe<Scalars['Int']>;
  lowTemperatureThreshold?: Maybe<Scalars['Int']>;
  monitorButton: Scalars['Boolean'];
  monitorDoor: Scalars['Boolean'];
  monitorHumidity: Scalars['Boolean'];
  monitorPower: Scalars['Boolean'];
  monitorTemperature: Scalars['Boolean'];
  monitorWater: Scalars['Boolean'];
  monitoringTypes?: Maybe<Array<Scalars['String']>>;
  monitoringTypesDisplay?: Maybe<Scalars['String']>;
  muteUntil?: Maybe<Scalars['ISO8601DateTime']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  probeType?: Maybe<Scalars['String']>;
  sensorLocationPhotoFull?: Maybe<Scalars['String']>;
  sensorRecentEvent?: Maybe<SensorRecentEvent>;
  unit?: Maybe<Unit>;
  unitId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
  waterSensitivity?: Maybe<Scalars['Int']>;
};

export type SensorCollection = {
  __typename?: 'SensorCollection';
  collection: Array<Sensor>;
  metadata: CollectionMetadata;
};

export type SensorEvent = {
  __typename?: 'SensorEvent';
  battery?: Maybe<Scalars['Float']>;
  batteryPercentage?: Maybe<Scalars['Int']>;
  co2?: Maybe<Scalars['Int']>;
  displayEventType?: Maybe<Scalars['String']>;
  doorOpen?: Maybe<Scalars['Boolean']>;
  eventTime: Scalars['ISO8601DateTime'];
  eventType?: Maybe<EventType>;
  gatewayId: Scalars['ID'];
  generatedAlert?: Maybe<Scalars['Boolean']>;
  humidity?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  lightLevel?: Maybe<Scalars['Int']>;
  messageId: Scalars['String'];
  messageType?: Maybe<Scalars['String']>;
  pm10?: Maybe<Scalars['Int']>;
  pm25?: Maybe<Scalars['Int']>;
  powerSource?: Maybe<Scalars['String']>;
  powerVoltage?: Maybe<Scalars['Float']>;
  pressure?: Maybe<Scalars['Float']>;
  probeResistance?: Maybe<Scalars['Int']>;
  sensor: Sensor;
  sensorId: Scalars['ID'];
  sensorMuted: Scalars['Boolean'];
  temperature?: Maybe<Scalars['Float']>;
  tvoc?: Maybe<Scalars['Float']>;
  tvocMgM3?: Maybe<Scalars['Float']>;
  waterPresence?: Maybe<Scalars['Boolean']>;
  waterPresenceSource?: Maybe<Scalars['String']>;
};

export type SensorEventCollection = {
  __typename?: 'SensorEventCollection';
  collection: Array<SensorEvent>;
  metadata: CollectionMetadata;
};

export type SensorRecentEvent = {
  __typename?: 'SensorRecentEvent';
  battery?: Maybe<Scalars['Float']>;
  batteryPercentage?: Maybe<Scalars['Int']>;
  co2?: Maybe<Scalars['Int']>;
  humidity?: Maybe<Scalars['Float']>;
  lightLevel?: Maybe<Scalars['Int']>;
  pm10?: Maybe<Scalars['Int']>;
  pm25?: Maybe<Scalars['Int']>;
  pressure?: Maybe<Scalars['Float']>;
  sensorId: Scalars['Int'];
  spl?: Maybe<Scalars['Float']>;
  splEq?: Maybe<Scalars['Float']>;
  splMax?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  tvoc?: Maybe<Scalars['Float']>;
  tvocMgM3?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Severity of Alert */
export enum Severity {
  /** critical */
  Critical = 'critical',
  /** high */
  High = 'high',
  /** low */
  Low = 'low',
  /** medium */
  Medium = 'medium'
}

/** Basic Sorting Input */
export type SortingType = {
  field?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type Unit = {
  __typename?: 'Unit';
  activeAlerts?: Maybe<Array<Alert>>;
  alertEscalationMinutes: Scalars['Int'];
  building?: Maybe<Building>;
  buildingId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  floor: Scalars['Int'];
  id: Scalars['ID'];
  lastAlert?: Maybe<Alert>;
  monitorForCleaning: Scalars['Boolean'];
  number?: Maybe<Scalars['String']>;
  sensors?: Maybe<Array<Sensor>>;
  sensorsCount: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
  users?: Maybe<Array<User>>;
};

/** Autogenerated input type of UpdateAlert */
export type UpdateAlertInput = {
  acknowledgedUserId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  feedback?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  severity: Severity;
};

/** Autogenerated input type of UpdateDashboard */
export type UpdateDashboardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateDashboardWidgetPosition */
export type UpdateDashboardWidgetPositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dashboardWidgetsIds: Array<Scalars['ID']>;
};

/** Autogenerated input type of UpdateGateway */
export type UpdateGatewayInput = {
  buildingId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  hardwareId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  inUse?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['ID']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateUserReport */
export type UpdateUserReportInput = {
  active: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  company?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  confirmationSentAt?: Maybe<Scalars['ISO8601DateTime']>;
  confirmationToken?: Maybe<Scalars['String']>;
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  currentSignInIp?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['ISO8601Date']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  householdIncomeRange?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  lastSignInIp?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<Scalars['String']>;
  numberChildrenUnder18?: Maybe<Scalars['Int']>;
  numberOfPets?: Maybe<Scalars['Int']>;
  phoneConfirmationCode?: Maybe<Scalars['String']>;
  phoneConfirmedAt?: Maybe<Scalars['ISO8601DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  rememberCreatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  resetPasswordSentAt?: Maybe<Scalars['ISO8601DateTime']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  signInCount: Scalars['Int'];
  termsSigned: Scalars['Boolean'];
  timezone?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  userBuildings?: Maybe<Array<UserBuilding>>;
  workingDays?: Maybe<Scalars['String']>;
  workingDaysEnabled?: Maybe<Scalars['Boolean']>;
  workingWeekendHolidays: Scalars['Boolean'];
};

export type UserAlert = {
  __typename?: 'UserAlert';
  acknowledgedAt?: Maybe<Scalars['ISO8601DateTime']>;
  alert: Alert;
  alertId: Scalars['ID'];
  id: Scalars['ID'];
  notSentOutsideHours: Scalars['Boolean'];
  sentAt: Scalars['ISO8601DateTime'];
  sentEmail: Scalars['Boolean'];
  sentMobile: Scalars['Boolean'];
  sentSms: Scalars['Boolean'];
  shortcode?: Maybe<Scalars['String']>;
  user: User;
  userId: Scalars['ID'];
};

export type UserBuilding = {
  __typename?: 'UserBuilding';
  active: Scalars['Boolean'];
  alertDoor: Scalars['Boolean'];
  alertHumidity: Scalars['Boolean'];
  alertPower: Scalars['Boolean'];
  alertTemperature: Scalars['Boolean'];
  alertWater: Scalars['Boolean'];
  building: Building;
  buildingId: Scalars['Int'];
  id: Scalars['ID'];
  notifyAlertAcknowledged?: Maybe<Scalars['Boolean']>;
  notifyEmail: Scalars['Boolean'];
  notifyMobile: Scalars['Boolean'];
  notifySms: Scalars['Boolean'];
  priority: Scalars['Int'];
  unit?: Maybe<Unit>;
  unitId?: Maybe<Scalars['Int']>;
  user: User;
  userId: Scalars['Int'];
};

export type UserReport = {
  __typename?: 'UserReport';
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  criteria?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  reportInterval: ReportInterval;
  reports?: Maybe<Array<Report>>;
  startDate: Scalars['ISO8601Date'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type UserReportCollection = {
  __typename?: 'UserReportCollection';
  collection: Array<UserReport>;
  metadata: CollectionMetadata;
};

/** Date Filter Types for Dashboard */
export enum WidgetDateFilterType {
  /** Withing Date Range */
  DateRange = 'date_range',
  /** Days ago */
  SinceDate = 'since_date'
}

/** Widget Filters Input - Dashboard */
export type WidgetFiltersType = {
  buildingIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilterType?: InputMaybe<WidgetDateFilterType>;
  endDate?: InputMaybe<Scalars['String']>;
  sensorIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['String']>;
  timeUnit?: InputMaybe<Scalars['String']>;
  timeValue?: InputMaybe<Scalars['Int']>;
  unitIds?: InputMaybe<Array<Scalars['ID']>>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Alert: ResolverTypeWrapper<Alert>;
  AlertCollection: ResolverTypeWrapper<AlertCollection>;
  AlertType: AlertType;
  Bar: ResolverTypeWrapper<Bar>;
  BarDataset: ResolverTypeWrapper<BarDataset>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Building: ResolverTypeWrapper<Building>;
  BuildingCollection: ResolverTypeWrapper<BuildingCollection>;
  CleaningRequest: ResolverTypeWrapper<CleaningRequest>;
  CleaningRequestCollection: ResolverTypeWrapper<CleaningRequestCollection>;
  CollectionMetadata: ResolverTypeWrapper<CollectionMetadata>;
  Company: ResolverTypeWrapper<Company>;
  CreateDashboardInput: CreateDashboardInput;
  CreateDashboardWidgetInput: CreateDashboardWidgetInput;
  CreateGatewayInput: CreateGatewayInput;
  CreateUserReportInput: CreateUserReportInput;
  Dashboard: ResolverTypeWrapper<Dashboard>;
  DashboardCollection: ResolverTypeWrapper<DashboardCollection>;
  DashboardWidget: ResolverTypeWrapper<DashboardWidget>;
  DashboardWidgetCollection: ResolverTypeWrapper<DashboardWidgetCollection>;
  DashboardWidgetType: DashboardWidgetType;
  DeleteDashboardInput: DeleteDashboardInput;
  DeleteDashboardWidgetInput: DeleteDashboardWidgetInput;
  DeleteGatewayInput: DeleteGatewayInput;
  EventType: EventType;
  Filters: Filters;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Gateway: ResolverTypeWrapper<Gateway>;
  GatewayCollection: ResolverTypeWrapper<GatewayCollection>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  ISO8601Date: ResolverTypeWrapper<Scalars['ISO8601Date']>;
  ISO8601DateTime: ResolverTypeWrapper<Scalars['ISO8601DateTime']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  LineDataset: ResolverTypeWrapper<LineDataset>;
  LineLabel: ResolverTypeWrapper<LineLabel>;
  Lines: ResolverTypeWrapper<Lines>;
  Location: ResolverTypeWrapper<Location>;
  LocationWeather: ResolverTypeWrapper<LocationWeather>;
  Mutation: ResolverTypeWrapper<{}>;
  PaginationType: PaginationType;
  Project: ResolverTypeWrapper<Project>;
  ProjectCollection: ResolverTypeWrapper<ProjectCollection>;
  Query: ResolverTypeWrapper<{}>;
  Report: ResolverTypeWrapper<Report>;
  ReportInterval: ReportInterval;
  Sensor: ResolverTypeWrapper<Sensor>;
  SensorCollection: ResolverTypeWrapper<SensorCollection>;
  SensorEvent: ResolverTypeWrapper<SensorEvent>;
  SensorEventCollection: ResolverTypeWrapper<SensorEventCollection>;
  SensorRecentEvent: ResolverTypeWrapper<SensorRecentEvent>;
  Severity: Severity;
  SortingType: SortingType;
  String: ResolverTypeWrapper<Scalars['String']>;
  Unit: ResolverTypeWrapper<Unit>;
  UpdateAlertInput: UpdateAlertInput;
  UpdateDashboardInput: UpdateDashboardInput;
  UpdateDashboardWidgetPositionInput: UpdateDashboardWidgetPositionInput;
  UpdateGatewayInput: UpdateGatewayInput;
  UpdateUserReportInput: UpdateUserReportInput;
  User: ResolverTypeWrapper<User>;
  UserAlert: ResolverTypeWrapper<UserAlert>;
  UserBuilding: ResolverTypeWrapper<UserBuilding>;
  UserReport: ResolverTypeWrapper<UserReport>;
  UserReportCollection: ResolverTypeWrapper<UserReportCollection>;
  WidgetDateFilterType: WidgetDateFilterType;
  WidgetFiltersType: WidgetFiltersType;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Alert: Alert;
  AlertCollection: AlertCollection;
  Bar: Bar;
  BarDataset: BarDataset;
  BigInt: Scalars['BigInt'];
  Boolean: Scalars['Boolean'];
  Building: Building;
  BuildingCollection: BuildingCollection;
  CleaningRequest: CleaningRequest;
  CleaningRequestCollection: CleaningRequestCollection;
  CollectionMetadata: CollectionMetadata;
  Company: Company;
  CreateDashboardInput: CreateDashboardInput;
  CreateDashboardWidgetInput: CreateDashboardWidgetInput;
  CreateGatewayInput: CreateGatewayInput;
  CreateUserReportInput: CreateUserReportInput;
  Dashboard: Dashboard;
  DashboardCollection: DashboardCollection;
  DashboardWidget: DashboardWidget;
  DashboardWidgetCollection: DashboardWidgetCollection;
  DeleteDashboardInput: DeleteDashboardInput;
  DeleteDashboardWidgetInput: DeleteDashboardWidgetInput;
  DeleteGatewayInput: DeleteGatewayInput;
  Filters: Filters;
  Float: Scalars['Float'];
  Gateway: Gateway;
  GatewayCollection: GatewayCollection;
  ID: Scalars['ID'];
  ISO8601Date: Scalars['ISO8601Date'];
  ISO8601DateTime: Scalars['ISO8601DateTime'];
  Int: Scalars['Int'];
  JSON: Scalars['JSON'];
  LineDataset: LineDataset;
  LineLabel: LineLabel;
  Lines: Lines;
  Location: Location;
  LocationWeather: LocationWeather;
  Mutation: {};
  PaginationType: PaginationType;
  Project: Project;
  ProjectCollection: ProjectCollection;
  Query: {};
  Report: Report;
  Sensor: Sensor;
  SensorCollection: SensorCollection;
  SensorEvent: SensorEvent;
  SensorEventCollection: SensorEventCollection;
  SensorRecentEvent: SensorRecentEvent;
  SortingType: SortingType;
  String: Scalars['String'];
  Unit: Unit;
  UpdateAlertInput: UpdateAlertInput;
  UpdateDashboardInput: UpdateDashboardInput;
  UpdateDashboardWidgetPositionInput: UpdateDashboardWidgetPositionInput;
  UpdateGatewayInput: UpdateGatewayInput;
  UpdateUserReportInput: UpdateUserReportInput;
  User: User;
  UserAlert: UserAlert;
  UserBuilding: UserBuilding;
  UserReport: UserReport;
  UserReportCollection: UserReportCollection;
  WidgetFiltersType: WidgetFiltersType;
};

export type AlertResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert'] = ResolversParentTypes['Alert']> = {
  acknowledgedUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  acknowledgedUserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  alertType?: Resolver<ResolversTypes['AlertType'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  feedback?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  sensorEvent?: Resolver<ResolversTypes['SensorEvent'], ParentType, ContextType>;
  sensorEventId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  severity?: Resolver<Maybe<ResolversTypes['Severity']>, ParentType, ContextType>;
  unit?: Resolver<ResolversTypes['Unit'], ParentType, ContextType>;
  unitId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  userAlerts?: Resolver<Array<ResolversTypes['UserAlert']>, ParentType, ContextType>;
  usersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertCollection'] = ResolversParentTypes['AlertCollection']> = {
  collection?: Resolver<Array<ResolversTypes['Alert']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['CollectionMetadata'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BarResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bar'] = ResolversParentTypes['Bar']> = {
  datasets?: Resolver<Array<ResolversTypes['BarDataset']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BarDatasetResolvers<ContextType = any, ParentType extends ResolversParentTypes['BarDataset'] = ResolversParentTypes['BarDataset']> = {
  data?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type BuildingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Building'] = ResolversParentTypes['Building']> = {
  activeSensorsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buildingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doorAlertMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floors?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  humidityAverage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  locationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateProvince?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  temperatureAverage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  waterAlertMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuildingCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuildingCollection'] = ResolversParentTypes['BuildingCollection']> = {
  collection?: Resolver<Array<ResolversTypes['Building']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['CollectionMetadata'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CleaningRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['CleaningRequest'] = ResolversParentTypes['CleaningRequest']> = {
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  initiatedInternally?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  resolvedAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  resolvedByUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  resolvedByUserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  sensorEvent?: Resolver<Maybe<ResolversTypes['SensorEvent']>, ParentType, ContextType>;
  sensorEventId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  unit?: Resolver<ResolversTypes['Unit'], ParentType, ContextType>;
  unitId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CleaningRequestCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CleaningRequestCollection'] = ResolversParentTypes['CleaningRequestCollection']> = {
  collection?: Resolver<Array<ResolversTypes['CleaningRequest']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['CollectionMetadata'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CollectionMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollectionMetadata'] = ResolversParentTypes['CollectionMetadata']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  limitValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  featureConfig?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Dashboard'] = ResolversParentTypes['Dashboard']> = {
  company?: Resolver<ResolversTypes['Company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  filters?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  selectedBuildings?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  selectedSensors?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  selectedUnits?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  widgets?: Resolver<Array<ResolversTypes['DashboardWidget']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardCollection'] = ResolversParentTypes['DashboardCollection']> = {
  collection?: Resolver<Array<ResolversTypes['Dashboard']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['CollectionMetadata'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardWidgetResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardWidget'] = ResolversParentTypes['DashboardWidget']> = {
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  dashboard?: Resolver<ResolversTypes['Dashboard'], ParentType, ContextType>;
  dashboardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DashboardWidgetType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardWidgetCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardWidgetCollection'] = ResolversParentTypes['DashboardWidgetCollection']> = {
  collection?: Resolver<Array<ResolversTypes['DashboardWidget']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['CollectionMetadata'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GatewayResolvers<ContextType = any, ParentType extends ResolversParentTypes['Gateway'] = ResolversParentTypes['Gateway']> = {
  buildingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  hardwareId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inUse?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  make?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  serialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GatewayCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GatewayCollection'] = ResolversParentTypes['GatewayCollection']> = {
  collection?: Resolver<Array<ResolversTypes['Gateway']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['CollectionMetadata'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface Iso8601DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ISO8601Date'], any> {
  name: 'ISO8601Date';
}

export interface Iso8601DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ISO8601DateTime'], any> {
  name: 'ISO8601DateTime';
}

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LineDatasetResolvers<ContextType = any, ParentType extends ResolversParentTypes['LineDataset'] = ResolversParentTypes['LineDataset']> = {
  data?: Resolver<Array<ResolversTypes['BigInt']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineLabelResolvers<ContextType = any, ParentType extends ResolversParentTypes['LineLabel'] = ResolversParentTypes['LineLabel']> = {
  from?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Lines'] = ResolversParentTypes['Lines']> = {
  datasets?: Resolver<Array<ResolversTypes['LineDataset']>, ParentType, ContextType>;
  labels?: Resolver<Array<ResolversTypes['LineLabel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  actualWeather?: Resolver<Maybe<ResolversTypes['LocationWeather']>, ParentType, ContextType>;
  buildings?: Resolver<Array<ResolversTypes['Building']>, ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  locationWeathers?: Resolver<Array<ResolversTypes['LocationWeather']>, ParentType, ContextType>;
  stateProvince?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationWeatherResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationWeather'] = ResolversParentTypes['LocationWeather']> = {
  condition?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  daytime?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  humidity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pressure?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recordedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  temperature?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createDashboard?: Resolver<ResolversTypes['Dashboard'], ParentType, ContextType, RequireFields<MutationCreateDashboardArgs, 'input'>>;
  createDashboardWidget?: Resolver<ResolversTypes['DashboardWidget'], ParentType, ContextType, RequireFields<MutationCreateDashboardWidgetArgs, 'input'>>;
  createGateway?: Resolver<ResolversTypes['Gateway'], ParentType, ContextType, RequireFields<MutationCreateGatewayArgs, 'input'>>;
  createUserReport?: Resolver<ResolversTypes['UserReport'], ParentType, ContextType, RequireFields<MutationCreateUserReportArgs, 'input'>>;
  deleteDashboard?: Resolver<ResolversTypes['Dashboard'], ParentType, ContextType, RequireFields<MutationDeleteDashboardArgs, 'input'>>;
  deleteDashboardWidget?: Resolver<ResolversTypes['DashboardWidget'], ParentType, ContextType, RequireFields<MutationDeleteDashboardWidgetArgs, 'input'>>;
  deleteGateway?: Resolver<ResolversTypes['Gateway'], ParentType, ContextType, RequireFields<MutationDeleteGatewayArgs, 'input'>>;
  updateAlert?: Resolver<ResolversTypes['Alert'], ParentType, ContextType, RequireFields<MutationUpdateAlertArgs, 'input'>>;
  updateDashboard?: Resolver<ResolversTypes['Dashboard'], ParentType, ContextType, RequireFields<MutationUpdateDashboardArgs, 'input'>>;
  updateDashboardWidgetPosition?: Resolver<Array<ResolversTypes['DashboardWidget']>, ParentType, ContextType, RequireFields<MutationUpdateDashboardWidgetPositionArgs, 'input'>>;
  updateGateway?: Resolver<ResolversTypes['Gateway'], ParentType, ContextType, RequireFields<MutationUpdateGatewayArgs, 'input'>>;
  updateUserReport?: Resolver<ResolversTypes['UserReport'], ParentType, ContextType, RequireFields<MutationUpdateUserReportArgs, 'input'>>;
};

export type ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['Project'] = ResolversParentTypes['Project']> = {
  buildingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  installDate?: Resolver<ResolversTypes['ISO8601Date'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectCollection'] = ResolversParentTypes['ProjectCollection']> = {
  collection?: Resolver<Array<ResolversTypes['Project']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['CollectionMetadata'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  fetchAlertData?: Resolver<ResolversTypes['JSON'], ParentType, ContextType, RequireFields<QueryFetchAlertDataArgs, 'eventFilter' | 'reportToken'>>;
  fetchAlerts?: Resolver<ResolversTypes['AlertCollection'], ParentType, ContextType, RequireFields<QueryFetchAlertsArgs, 'eventFilter' | 'pagination' | 'query' | 'reportToken'>>;
  fetchBuildings?: Resolver<Array<ResolversTypes['Building']>, ParentType, ContextType, RequireFields<QueryFetchBuildingsArgs, 'companyId' | 'ids' | 'pagination' | 'query'>>;
  fetchCompanies?: Resolver<Array<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<QueryFetchCompaniesArgs, 'pagination'>>;
  fetchDashboardWidgets?: Resolver<ResolversTypes['DashboardWidgetCollection'], ParentType, ContextType, RequireFields<QueryFetchDashboardWidgetsArgs, 'pagination' | 'token'>>;
  fetchDashboards?: Resolver<ResolversTypes['DashboardCollection'], ParentType, ContextType, RequireFields<QueryFetchDashboardsArgs, 'pagination'>>;
  fetchGateways?: Resolver<ResolversTypes['GatewayCollection'], ParentType, ContextType, RequireFields<QueryFetchGatewaysArgs, 'pagination' | 'query'>>;
  fetchIaqChartData?: Resolver<ResolversTypes['JSON'], ParentType, ContextType, RequireFields<QueryFetchIaqChartDataArgs, 'breakpointsCount' | 'filters' | 'linesCount' | 'reportToken'>>;
  fetchIaqData?: Resolver<ResolversTypes['JSON'], ParentType, ContextType, RequireFields<QueryFetchIaqDataArgs, 'filters' | 'pagination' | 'reportToken'>>;
  fetchIaqOutliers?: Resolver<ResolversTypes['JSON'], ParentType, ContextType, RequireFields<QueryFetchIaqOutliersArgs, 'filters' | 'iaqAttribute' | 'lowHigh' | 'reportToken'>>;
  fetchOccupancyData?: Resolver<ResolversTypes['JSON'], ParentType, ContextType, RequireFields<QueryFetchOccupancyDataArgs, 'filters' | 'reportToken'>>;
  fetchProjects?: Resolver<ResolversTypes['ProjectCollection'], ParentType, ContextType, Partial<QueryFetchProjectsArgs>>;
  fetchSensorEvents?: Resolver<ResolversTypes['SensorEventCollection'], ParentType, ContextType, RequireFields<QueryFetchSensorEventsArgs, 'pagination'>>;
  fetchSensors?: Resolver<ResolversTypes['SensorCollection'], ParentType, ContextType, RequireFields<QueryFetchSensorsArgs, 'buildingId' | 'pagination' | 'unitId'>>;
  fetchSensorsCollection?: Resolver<ResolversTypes['SensorCollection'], ParentType, ContextType, RequireFields<QueryFetchSensorsCollectionArgs, 'buildingIds' | 'companyId' | 'ids' | 'pagination' | 'query' | 'unitIds'>>;
  fetchUnits?: Resolver<Array<ResolversTypes['Unit']>, ParentType, ContextType, RequireFields<QueryFetchUnitsArgs, 'buildingIds' | 'companyId' | 'ids' | 'pagination' | 'query'>>;
  fetchUserBuildings?: Resolver<Array<ResolversTypes['UserBuilding']>, ParentType, ContextType, RequireFields<QueryFetchUserBuildingsArgs, 'filters' | 'pagination'>>;
  fetchUserReports?: Resolver<ResolversTypes['UserReportCollection'], ParentType, ContextType, RequireFields<QueryFetchUserReportsArgs, 'pagination'>>;
  fetchUsers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  getBuilding?: Resolver<ResolversTypes['Building'], ParentType, ContextType, RequireFields<QueryGetBuildingArgs, 'id'>>;
  getCurrentUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  getDashboard?: Resolver<ResolversTypes['Dashboard'], ParentType, ContextType, RequireFields<QueryGetDashboardArgs, 'token'>>;
  getGateway?: Resolver<ResolversTypes['Gateway'], ParentType, ContextType, RequireFields<QueryGetGatewayArgs, 'id'>>;
  getReport?: Resolver<ResolversTypes['Report'], ParentType, ContextType, RequireFields<QueryGetReportArgs, 'token'>>;
  getSensor?: Resolver<ResolversTypes['Sensor'], ParentType, ContextType, RequireFields<QueryGetSensorArgs, 'id'>>;
  getUserAlert?: Resolver<ResolversTypes['UserAlert'], ParentType, ContextType, RequireFields<QueryGetUserAlertArgs, 'shortcode'>>;
  widgetActiveSensors?: Resolver<ResolversTypes['BuildingCollection'], ParentType, ContextType, RequireFields<QueryWidgetActiveSensorsArgs, 'pagination' | 'sorting' | 'token'>>;
  widgetAverageCo2Gauges?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryWidgetAverageCo2GaugesArgs, 'token'>>;
  widgetAverageHumidityGauges?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryWidgetAverageHumidityGaugesArgs, 'token'>>;
  widgetAveragePressureGauges?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryWidgetAveragePressureGaugesArgs, 'token'>>;
  widgetAverageTemperatureGauges?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryWidgetAverageTemperatureGaugesArgs, 'token'>>;
  widgetCleaningRequestLines?: Resolver<ResolversTypes['Lines'], ParentType, ContextType, RequireFields<QueryWidgetCleaningRequestLinesArgs, 'breakpointsCount' | 'linesCount' | 'token'>>;
  widgetCleaningRequestPolars?: Resolver<Maybe<ResolversTypes['Bar']>, ParentType, ContextType, RequireFields<QueryWidgetCleaningRequestPolarsArgs, 'token'>>;
  widgetCleaningRequests?: Resolver<ResolversTypes['CleaningRequestCollection'], ParentType, ContextType, RequireFields<QueryWidgetCleaningRequestsArgs, 'pagination' | 'sorting' | 'token'>>;
  widgetCo2ActivityLines?: Resolver<ResolversTypes['Lines'], ParentType, ContextType, RequireFields<QueryWidgetCo2ActivityLinesArgs, 'breakpointsCount' | 'linesCount' | 'token'>>;
  widgetCo2ActivityPolars?: Resolver<Maybe<ResolversTypes['Bar']>, ParentType, ContextType, RequireFields<QueryWidgetCo2ActivityPolarsArgs, 'token'>>;
  widgetDoorActivities?: Resolver<ResolversTypes['SensorEventCollection'], ParentType, ContextType, RequireFields<QueryWidgetDoorActivitiesArgs, 'pagination' | 'sorting' | 'token'>>;
  widgetDoorActivityBars?: Resolver<Maybe<ResolversTypes['Bar']>, ParentType, ContextType, RequireFields<QueryWidgetDoorActivityBarsArgs, 'token'>>;
  widgetExteriorTemperatures?: Resolver<ResolversTypes['BuildingCollection'], ParentType, ContextType, RequireFields<QueryWidgetExteriorTemperaturesArgs, 'pagination' | 'sorting' | 'token'>>;
  widgetHumidityActivityLines?: Resolver<ResolversTypes['Lines'], ParentType, ContextType, RequireFields<QueryWidgetHumidityActivityLinesArgs, 'breakpointsCount' | 'linesCount' | 'token'>>;
  widgetPowerStatuses?: Resolver<ResolversTypes['BuildingCollection'], ParentType, ContextType, RequireFields<QueryWidgetPowerStatusesArgs, 'pagination' | 'sorting' | 'token'>>;
  widgetPressureActivityLines?: Resolver<ResolversTypes['Lines'], ParentType, ContextType, RequireFields<QueryWidgetPressureActivityLinesArgs, 'breakpointsCount' | 'linesCount' | 'token'>>;
  widgetTemperatureActivities?: Resolver<ResolversTypes['Lines'], ParentType, ContextType, RequireFields<QueryWidgetTemperatureActivitiesArgs, 'breakpointsCount' | 'linesCount' | 'token'>>;
  widgetTemperaturePolars?: Resolver<Maybe<ResolversTypes['Bar']>, ParentType, ContextType, RequireFields<QueryWidgetTemperaturePolarsArgs, 'token'>>;
  widgetWaterAlerts?: Resolver<ResolversTypes['SensorEventCollection'], ParentType, ContextType, RequireFields<QueryWidgetWaterAlertsArgs, 'pagination' | 'sorting' | 'token'>>;
};

export type ReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Report'] = ResolversParentTypes['Report']> = {
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  criteria?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  selectedBuildings?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  selectedSensors?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  selectedUnits?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  sentTo?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userReport?: Resolver<ResolversTypes['UserReport'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SensorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sensor'] = ResolversParentTypes['Sensor']> = {
  building?: Resolver<Maybe<ResolversTypes['Building']>, ParentType, ContextType>;
  buildingId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  deviceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doorAlertDays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doorAlertEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doorAlertHoursEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  doorAlertStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doorAlertWeekendHolidays?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  enableAlerts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hardwareId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highHumidityThreshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  highTemperatureThreshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inUse?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMuted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lowHumidityThreshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lowTemperatureThreshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  monitorButton?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  monitorDoor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  monitorHumidity?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  monitorPower?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  monitorTemperature?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  monitorWater?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  monitoringTypes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  monitoringTypesDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  muteUntil?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  probeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sensorLocationPhotoFull?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sensorRecentEvent?: Resolver<Maybe<ResolversTypes['SensorRecentEvent']>, ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['Unit']>, ParentType, ContextType>;
  unitId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  waterSensitivity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SensorCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SensorCollection'] = ResolversParentTypes['SensorCollection']> = {
  collection?: Resolver<Array<ResolversTypes['Sensor']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['CollectionMetadata'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SensorEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['SensorEvent'] = ResolversParentTypes['SensorEvent']> = {
  battery?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  batteryPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  co2?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  displayEventType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doorOpen?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  eventTime?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  eventType?: Resolver<Maybe<ResolversTypes['EventType']>, ParentType, ContextType>;
  gatewayId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  generatedAlert?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  humidity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lightLevel?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  messageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messageType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pm10?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pm25?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  powerSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerVoltage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pressure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  probeResistance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sensor?: Resolver<ResolversTypes['Sensor'], ParentType, ContextType>;
  sensorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  sensorMuted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  temperature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tvoc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tvocMgM3?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  waterPresence?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  waterPresenceSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SensorEventCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SensorEventCollection'] = ResolversParentTypes['SensorEventCollection']> = {
  collection?: Resolver<Array<ResolversTypes['SensorEvent']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['CollectionMetadata'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SensorRecentEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['SensorRecentEvent'] = ResolversParentTypes['SensorRecentEvent']> = {
  battery?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  batteryPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  co2?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  humidity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lightLevel?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pm10?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pm25?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pressure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sensorId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  spl?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  splEq?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  splMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  temperature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tvoc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tvocMgM3?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['Unit'] = ResolversParentTypes['Unit']> = {
  activeAlerts?: Resolver<Maybe<Array<ResolversTypes['Alert']>>, ParentType, ContextType>;
  alertEscalationMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  building?: Resolver<Maybe<ResolversTypes['Building']>, ParentType, ContextType>;
  buildingId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  floor?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastAlert?: Resolver<Maybe<ResolversTypes['Alert']>, ParentType, ContextType>;
  monitorForCleaning?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sensors?: Resolver<Maybe<Array<ResolversTypes['Sensor']>>, ParentType, ContextType>;
  sensorsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  users?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  confirmationSentAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  confirmationToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  confirmedAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  currentSignInAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  currentSignInIp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dob?: Resolver<Maybe<ResolversTypes['ISO8601Date']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  householdIncomeRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastSignInAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  lastSignInIp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maritalStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberChildrenUnder18?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numberOfPets?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  phoneConfirmationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneConfirmedAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rememberCreatedAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  resetPasswordSentAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  resetPasswordToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signInCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  termsSigned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  userBuildings?: Resolver<Maybe<Array<ResolversTypes['UserBuilding']>>, ParentType, ContextType>;
  workingDays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workingDaysEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  workingWeekendHolidays?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAlertResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAlert'] = ResolversParentTypes['UserAlert']> = {
  acknowledgedAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>;
  alert?: Resolver<ResolversTypes['Alert'], ParentType, ContextType>;
  alertId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notSentOutsideHours?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sentAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  sentEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sentMobile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sentSms?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shortcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBuildingResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserBuilding'] = ResolversParentTypes['UserBuilding']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alertDoor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alertHumidity?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alertPower?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alertTemperature?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alertWater?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  building?: Resolver<ResolversTypes['Building'], ParentType, ContextType>;
  buildingId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notifyAlertAcknowledged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  notifyEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notifyMobile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notifySms?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['Unit']>, ParentType, ContextType>;
  unitId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReport'] = ResolversParentTypes['UserReport']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  criteria?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reportInterval?: Resolver<ResolversTypes['ReportInterval'], ParentType, ContextType>;
  reports?: Resolver<Maybe<Array<ResolversTypes['Report']>>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['ISO8601Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserReportCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReportCollection'] = ResolversParentTypes['UserReportCollection']> = {
  collection?: Resolver<Array<ResolversTypes['UserReport']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['CollectionMetadata'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Alert?: AlertResolvers<ContextType>;
  AlertCollection?: AlertCollectionResolvers<ContextType>;
  Bar?: BarResolvers<ContextType>;
  BarDataset?: BarDatasetResolvers<ContextType>;
  BigInt?: GraphQLScalarType;
  Building?: BuildingResolvers<ContextType>;
  BuildingCollection?: BuildingCollectionResolvers<ContextType>;
  CleaningRequest?: CleaningRequestResolvers<ContextType>;
  CleaningRequestCollection?: CleaningRequestCollectionResolvers<ContextType>;
  CollectionMetadata?: CollectionMetadataResolvers<ContextType>;
  Company?: CompanyResolvers<ContextType>;
  Dashboard?: DashboardResolvers<ContextType>;
  DashboardCollection?: DashboardCollectionResolvers<ContextType>;
  DashboardWidget?: DashboardWidgetResolvers<ContextType>;
  DashboardWidgetCollection?: DashboardWidgetCollectionResolvers<ContextType>;
  Gateway?: GatewayResolvers<ContextType>;
  GatewayCollection?: GatewayCollectionResolvers<ContextType>;
  ISO8601Date?: GraphQLScalarType;
  ISO8601DateTime?: GraphQLScalarType;
  JSON?: GraphQLScalarType;
  LineDataset?: LineDatasetResolvers<ContextType>;
  LineLabel?: LineLabelResolvers<ContextType>;
  Lines?: LinesResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LocationWeather?: LocationWeatherResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Project?: ProjectResolvers<ContextType>;
  ProjectCollection?: ProjectCollectionResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Report?: ReportResolvers<ContextType>;
  Sensor?: SensorResolvers<ContextType>;
  SensorCollection?: SensorCollectionResolvers<ContextType>;
  SensorEvent?: SensorEventResolvers<ContextType>;
  SensorEventCollection?: SensorEventCollectionResolvers<ContextType>;
  SensorRecentEvent?: SensorRecentEventResolvers<ContextType>;
  Unit?: UnitResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserAlert?: UserAlertResolvers<ContextType>;
  UserBuilding?: UserBuildingResolvers<ContextType>;
  UserReport?: UserReportResolvers<ContextType>;
  UserReportCollection?: UserReportCollectionResolvers<ContextType>;
};

