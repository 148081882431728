import React from 'react'
import { Grid } from '@mui/material'

import { NUMBER_OF_ROWS_PER_PAGE_FIVE } from '../../../common/constants/pagination'

import Card from '../../molecules/Card'
import AlertsTable from '../../organisms/AlertsTable'
import SensorShowInformation from '../../organisms/SensorShowInformation'
import SensorEventsTable from '../../organisms/SensorEventsTable/SensorEventsTable'

import useSensorPage from './useSensorPage'

const SensorPage = () => {
  const { loading, userType, sensor, building, unit, sensorLocationPhoto } = useSensorPage()

  const tableContainerFixedHeight = '600px' // height of tables "parent" card

  // height of the actual table relative to the "parent" card's height (tableContainerFixedHeight)
  const innerTableRelativeHeightAlerts = '80%' // only 80% to account for the event type filter
  const innerTableRelativeHeightSensorEvents = '90%'

  if (loading) return <React.Fragment></React.Fragment>
  return (
    <>
      <Grid container spacing={4}>
        {/* Left side of page */}
        <Grid item xs={12} lg={3}>
          <Card hasCardHeader={false} maxWidth={false} containerSx={{ minHeight: tableContainerFixedHeight }}>
            <SensorShowInformation
              sensor={sensor}
              sensorLocationPhoto={sensorLocationPhoto}
              building={building}
              unit={unit}
            />
          </Card>
        </Grid>

        {/* Right side of page */}
        <Grid item xs={12} lg={9}>
          <Card
            title={'Alerts'}
            maxWidth={false}
            containerSx={{ height: tableContainerFixedHeight }}
            gridSx={{ height: innerTableRelativeHeightAlerts }}
          >
            <AlertsTable
              building={building}
              sensor={sensor}
              showEventTypeFilter={true}
              numRowsPerPage={NUMBER_OF_ROWS_PER_PAGE_FIVE}
              tableContainerSx={{
                height: '100%' /* ensures table takes up 100% of innerTableRelativeHeight */
              }}
              tableSx={{
                height: '90%' /* actual table height. Need to ensure room for pagination buttons */
              }}
            />
          </Card>
          <br />

          <Card
            title={'Sensor Events'}
            maxWidth={false}
            containerSx={{ height: tableContainerFixedHeight }}
            gridSx={{ height: innerTableRelativeHeightSensorEvents }}
          >
            <SensorEventsTable
              sensor={sensor}
              building={building}
              userType={userType}
              numRowsPerPage={NUMBER_OF_ROWS_PER_PAGE_FIVE}
              tableContainerSx={{
                height: '100%' /* ensures table takes up 100% of innerTableRelativeHeight */
              }}
              tableSx={{
                height: '90%' /* actual table height. Need to ensure room for pagination buttons */
              }}
            />
          </Card>
          <br />
        </Grid>
      </Grid>
    </>
  )
}

export default SensorPage
