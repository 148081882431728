import React, { Fragment } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableCell,
  Grid,
  CircularProgress
} from '@mui/material'
import { isEmpty } from 'lodash'

import { unitLabel } from '../../../common/utils/buildings'
import usePagination from '../../../common/hooks/usePagination'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'

import EventTypeFilter from '../../atoms/EventTypeFilter/EventTypeFilter'
import SearchBar from '../../atoms/SearchBar/SearchBar'
import AlertListRow from '../../molecules/AlertListRow'

import useAlertsTable from './useAlertsTable'
import { TEMPERATURE_LABEL, HUMIDITY_LABEL } from '../../../common/constants/reports'

const AlertsTable = ({
  building,
  sensor,
  hideUnitColumn = false,
  numRowsPerPage,
  tableContainerSx = {},
  tableSx = {},
  showNoRecordsMessage = true,
  showSearchBar = false,
  showEventTypeFilter = false,
  reportFilters,
  reportToken
}) => {
  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions } =
    usePagination({ numRowsPerPage })

  const {
    alerts,
    metadata,
    hasTemperature,
    hasHumidity,
    query,
    setQuery,
    eventFilter,
    setEventFilter,
    loading
  } = useAlertsTable({
    buildingId: building?.id,
    sensorId: sensor?.id,
    reportFilters: reportFilters,
    reportToken: reportToken,
    page,
    setPage,
    rowsPerPage
  })

  const numberOfColumns = 11 // used for "No Alerts Recorded" message
  return (
    <TableContainer sx={tableContainerSx}>
      <Grid container alignItems='center' spacing={3}>
        {showSearchBar && <SearchBar placeholder={'Search Alerts'} query={query} setQuery={setQuery} />}
        {showEventTypeFilter && <EventTypeFilter eventFilter={eventFilter} setEventFilter={setEventFilter} />}
        <Grid item xs={8} />
      </Grid>
      <Table aria-label='collapsible table' sx={tableSx}>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            {reportFilters && <StyledTableCell align='left'>Building</StyledTableCell>}
            {!hideUnitColumn && <StyledTableCell>{unitLabel(building)}</StyledTableCell>}
            <StyledTableCell align='left'>Sensor Name</StyledTableCell>
            <StyledTableCell align='left'>Sensor Location</StyledTableCell>
            <StyledTableCell align='center'>Alert For</StyledTableCell>
            <StyledTableCell align='center'>Severity</StyledTableCell>
            {hasTemperature && <StyledTableCell align='left'>{TEMPERATURE_LABEL}</StyledTableCell>}
            {hasHumidity && <StyledTableCell align='left'>{HUMIDITY_LABEL}</StyledTableCell>}
            <StyledTableCell align='left'>Users</StyledTableCell>
            <StyledTableCell align='left'>Sent At or Event Time</StyledTableCell>
            <StyledTableCell align='left'>Acknowledged</StyledTableCell>
            <StyledTableCell align='left'>Sent Via</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {showNoRecordsMessage && isEmpty(alerts) && (
            <StyledTableRow hover>
              <TableCell align='center' sx={{ verticalAlign: 'top' }} colSpan={numberOfColumns}>
                {loading ? <CircularProgress /> : <h4>No Alerts Recorded</h4>}
              </TableCell>
            </StyledTableRow>
          )}

          {alerts.map((alert) => (
            <Fragment key={alert.createdAt}>
              <AlertListRow
                alert={alert}
                hasTemperature={hasTemperature}
                hasHumidity={hasHumidity}
                hideUnitColumn={hideUnitColumn}
                hasBuilding={reportFilters !== undefined}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        colSpan={4}
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={metadata?.totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={isEmpty(metadata) ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default AlertsTable
