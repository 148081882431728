import React, { Fragment, useState } from 'react'
import { fullDateTimeFormat, dateFormat } from 'common/utils/dates'
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material'
import { find, join } from 'lodash'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'
import { REPORT_INTERVAL_SELECT } from '../../../common/constants/reports'
import { useNavigate } from 'react-router-dom'
import ClipboardCopy from '../ClipboardCopy'
import { getReportUrl, getMetricsList } from '../../../common/utils/reports'

const UserReportListRow = ({ userReport, toggleSending }) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <Fragment key={userReport.id}>
      <StyledTableRow hover>
        <TableCell style={{ maxWidth: 15, paddingBottom: 0, paddingTop: 0 }}>
          {userReport.reports.length > 0 && (
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{userReport.name}</TableCell>
        <TableCell>{find(REPORT_INTERVAL_SELECT, ['key', userReport.reportInterval]).value}</TableCell>
        <TableCell>{userReport.startDate}</TableCell>
        <TableCell>{join(getMetricsList(userReport['criteria']), ', ')}</TableCell>
        <TableCell>
          {userReport.reports.length ? fullDateTimeFormat(userReport.reports[0].createdAt) : '—'}
        </TableCell>
        <TableCell align='right'>
          <Button
            color={userReport.active ? 'primary' : 'success'}
            variant='outlined'
            size='small'
            style={{ textTransform: 'none' }}
            onClick={toggleSending}
          >
            {userReport.active ? 'Disable ' : 'Enable '}Sending
          </Button>
        </TableCell>
      </StyledTableRow>
      {userReport.reports.length > 0 && (
        <>
          <StyledTableRow>
            <TableCell colSpan={10} className={'report-runs-row'}>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box sx={{ m: 1, pb: 2 }}>
                  <Typography variant='subtitle1' sx={{ pt: 1 }}>
                    Report Runs
                  </Typography>
                  <Table size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ width: '15%', minWidth: 180 }}>Created On</StyledTableCell>
                        <StyledTableCell sx={{ width: '40%' }}>Date Range</StyledTableCell>
                        <StyledTableCell>Sent to</StyledTableCell>
                        <StyledTableCell align='center' sx={{ minWidth: 160 }}>
                          Copy Report Link
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 165 }}></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userReport.reports.map((reportRow) => (
                        <StyledTableRow key={reportRow.id}>
                          <TableCell className={'report-runs-cell'} component='th' scope='row'>
                            {fullDateTimeFormat(reportRow.createdAt)}
                          </TableCell>
                          <TableCell className={'report-runs-cell'}>
                            {dateFormat(reportRow.criteria.startDate) +
                              ' — ' +
                              dateFormat(reportRow.criteria.endDate)}
                          </TableCell>
                          <TableCell>{reportRow.sentTo.join(' ')}</TableCell>
                          <TableCell align='center'>
                            <ClipboardCopy text={getReportUrl(reportRow.token)} />
                          </TableCell>
                          <TableCell align='right'>
                            <Button
                              variant='contained'
                              size='small'
                              onClick={() => navigate(`/reports/${reportRow.token}`)}
                            >
                              View Report
                            </Button>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </StyledTableRow>
          <StyledTableRow />
        </>
      )}
    </Fragment>
  )
}

export default UserReportListRow
