import React from 'react'
import { Paper } from '@mui/material'

import useAlertsPage from './useAlertsPage'

import AlertsTable from '../../organisms/AlertsTable'

const AlertsPage = () => {
  const { building, loading } = useAlertsPage()

  if (loading) return <></>
  return (
    <Paper sx={{ p: 4 }}>
      <AlertsTable building={building} showSearchBar={true} showEventTypeFilter={true} />
    </Paper>
  )
}

export default AlertsPage
