import React from 'react'
import TableCell from '@mui/material/TableCell'

import { fullDateTimeFormat } from '../../../common/utils/dates'
import { getNumberRounded } from '../../../common/utils/numbers'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'

import CheckmarkCell from '../../atoms/Cells/CheckmarkCell'
import SensorEventBatteryPercentageCell from '../../atoms/Cells/BatteryPercentageCell'
import MenuDropdown from '../MenuDropdown'

import useSensorListRow from './useSensorListRow'
import { MenuItem } from '@mui/material'

const SensorListRow = ({ sensor, showSensorColumns, isOccupant, isSuperadmin, hideUnitColumn }) => {
  const { handleViewSensorMenuItemClick } = useSensorListRow()

  const sensorRecentEvent = sensor.sensorRecentEvent || {}

  return (
    <StyledTableRow hover>
      <TableCell align='left'>{sensor.deviceName}</TableCell>
      <TableCell align='left'>{sensor.location}</TableCell>
      {!hideUnitColumn && <TableCell align='left'>{sensor.unit.number}</TableCell>}
      <TableCell
        className={sensor.monitoringTypesDisplay.match('Sensor not') ? 'warning-text' : ''}
        align='left'
      >
        {sensor.monitoringTypesDisplay}
      </TableCell>
      <TableCell align='left'>{fullDateTimeFormat(sensorRecentEvent.updatedAt)}</TableCell>
      {showSensorColumns.temperature && <TableCell align='left'>{sensorRecentEvent.temperature}</TableCell>}
      {showSensorColumns.humidity && <TableCell align='left'>{sensorRecentEvent.humidity}</TableCell>}
      {showSensorColumns.co2 && <TableCell align='left'>{sensorRecentEvent.co2}</TableCell>}
      {showSensorColumns.pressure && (
        <TableCell align='left'>{getNumberRounded(sensorRecentEvent.pressure)}</TableCell>
      )}
      {showSensorColumns.lightLevel && <TableCell align='left'>{sensorRecentEvent.lightLevel}</TableCell>}
      {showSensorColumns.tvocMgM3 && <TableCell align='left'>{sensorRecentEvent.tvocMgM3}</TableCell>}
      {showSensorColumns.pm10 && <TableCell align='left'>{sensorRecentEvent.pm10}</TableCell>}
      {showSensorColumns.pm25 && <TableCell align='left'>{sensorRecentEvent.pm25}</TableCell>}
      {showSensorColumns.isMuted && (
        <TableCell align='left'>{fullDateTimeFormat(sensor.muteUntil)}</TableCell>
      )}
      {!isOccupant && <SensorEventBatteryPercentageCell sensorEvent={sensorRecentEvent} />}
      {!isOccupant && <CheckmarkCell booleanValue={sensor.inUse} />}
      {isSuperadmin && <TableCell align='left'>{sensor.hardwareId}</TableCell>}
      <TableCell align='right'>
        <MenuDropdown>
          <MenuItem onClick={() => handleViewSensorMenuItemClick(sensor)}>View Sensor</MenuItem>
        </MenuDropdown>
      </TableCell>
    </StyledTableRow>
  )
}

export default SensorListRow
