import React, { Fragment, useEffect, useMemo, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import AlertsTable from '../../organisms/AlertsTable'

import { isEmpty, omit } from 'lodash'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AlertsSummaryCard from '../../organisms/Reports/AlertsSummary'
import CreateUserReportDialog from '../../organisms/UserReports/CreateUserReportDialog'
import {
  ALERT_REPORT_DISPLAY,
  OCCUPANCY_REPORT_DISPLAY,
  TEMPERATURE_REPORT_DISPLAY,
  IAQ_REPORT_DISPLAY
} from '../../../common/constants/reports'
import useReportsPage from './useReportsPage'
import { useNavigate } from 'react-router-dom'
import TemperatureSummaryCard from '../../organisms/Reports/TemperatureSummary'
import CriteriaDisplay from './CriteriaDisplay'
import { useGetCurrentUserQuery } from '../dashboards/DashboardsList/gql.generated'
import IAQSummaryCard from '../../organisms/Reports/IAQSummary'
import { fetchCriteria, oneMonthAgo } from '../../../common/utils/reports'
import { initialFiltersState } from '../../../common/utils/reports'
import { useFetchBuildingsLazyQuery } from '../dashboards/components/DashboardFiltersPanel/gql.generated'
import OccupancySummaryCard from '../../organisms/Reports/OccupancySummary'

const ReportsPage = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const buildingId = query.get('buildingMonthly')
  const navigate = useNavigate()
  const previewMode = !isEmpty(location.state) || !isEmpty(buildingId)
  const [filterCriteria, setFilterCriteria] = useState({})
  const [criteriaDisplay, setCriteriaDisplay] = useState({})
  const [loading, setLoading] = useState(isEmpty(location.state?.data))
  const { data: userData } = useGetCurrentUserQuery()
  const user = userData?.getCurrentUser
  let reportDetails

  if (!previewMode) reportDetails = useReportsPage({ setFilterCriteria, location, setLoading })

  const queryCriteria = fetchCriteria(filterCriteria)

  const [isExpanded, setIsExpanded] = useState(true) // Setting the initial state to true

  const handleAccordionChange = (event, newExpandedState) => {
    setIsExpanded(newExpandedState)
  }
  const [getBuildings, { data: buildingsData, loading: buildingsLoading }] = useFetchBuildingsLazyQuery({
    variables: {
      ids: [buildingId]
    }
  })

  if (!isEmpty(buildingId)) {
    const metrics = query.get('metrics')
    location['state'] = {}
    location['state']['data'] = {
      ...initialFiltersState,
      alertsDisplay: true,
      iaqDisplay: metrics.includes('iaq') || metrics.includes('co2'),
      occupancyDisplay: metrics.includes('occupancy'),
      buildingIds: [buildingId],
      // startDate: new Date(new Date().getFullYear(), 0, 1),
      startDate: oneMonthAgo,
      endDate: new Date()
    }
  }

  useEffect(() => {
    if (isEmpty(buildingId)) return
    getBuildings()
  }, [buildingId])

  useEffect(() => {
    if (!isEmpty(buildingId)) {
      location['state']['criteriaDisplay'] = {
        buildings: buildingsData?.fetchBuildings
      }
    }
    if (previewMode) {
      setFilterCriteria(location.state?.data)
      setCriteriaDisplay(location.state?.criteriaDisplay)
      setLoading(false)
    }
  }, [buildingsData])

  return (
    <>
      {previewMode && !filterCriteria.drilldown ? (
        <CreateUserReportDialog
          previewContext={!previewMode}
          saveFilters={filterCriteria}
          loading={loading}
        />
      ) : (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h6'>{reportDetails?.userReport.name}</Typography>
          {user && (
            <Button variant='contained' onClick={() => navigate('/user_reports')}>
              My Reports
            </Button>
          )}
        </Box>
      )}

      <Box sx={{ mt: 1 }}>
        <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon>Hide</ExpandMoreIcon>}>
            <Typography variant='h5' sx={{ width: '100%', textAlign: 'center' }}>
              Report Criteria
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              className={'report-criteria'}
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{ overflowX: 'auto', pb: 2 }}
            >
              {' '}
              {reportDetails || previewMode ? (
                loading ? (
                  <Grid
                    item
                    container
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: 20
                    }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  <>
                    <CriteriaDisplay
                      previewMode={previewMode || filterCriteria?.drilldown}
                      savedReportDetails={reportDetails}
                      filterCriteria={filterCriteria}
                      criteriaDisplay={criteriaDisplay}
                    />
                  </>
                )
              ) : (
                <Typography variant='h6' sx={{ width: '100%', textAlign: 'center' }}>
                  Report Not Found
                </Typography>
              )}
            </Grid>
          </AccordionDetails>
          {/*  TODO: Add ability to edit criteria for unsaved reports */}
        </Accordion>
        {filterCriteria?.[ALERT_REPORT_DISPLAY] && (
          <>
            <AlertsSummaryCard
              filters={queryCriteria}
              reportToken={!previewMode ? reportDetails.token : null}
            />
            <br />
            <Paper sx={{ p: 4 }}>
              <h4 align={'center'}>Alert Details</h4>
              <AlertsTable
                reportFilters={queryCriteria}
                reportToken={!previewMode ? reportDetails.token : null}
                showEventTypeFilter
                showSearchBar={false}
              />
            </Paper>
          </>
        )}
        {filterCriteria?.[OCCUPANCY_REPORT_DISPLAY] && (
          <OccupancySummaryCard
            filterCriteria={filterCriteria}
            reportToken={!previewMode ? reportDetails.token : null}
            user={user}
          />
        )}
        {filterCriteria?.[TEMPERATURE_REPORT_DISPLAY] && (
          <>
            <TemperatureSummaryCard filters={queryCriteria} />
          </>
        )}
        {filterCriteria?.[IAQ_REPORT_DISPLAY] && (
          <>
            <IAQSummaryCard
              criteriaDisplay={criteriaDisplay}
              setCriteriaDisplay={setCriteriaDisplay}
              setFilterCriteria={setFilterCriteria}
              filterCriteria={filterCriteria}
              reportToken={!previewMode ? reportDetails.token : null}
              user={user}
            />
          </>
        )}
      </Box>
    </>
  )
}

export default ReportsPage
