import React, { Fragment } from 'react'
import { Table, TableBody, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material'

import { unitLabel } from '../../../common/utils/buildings'
import usePagination from '../../../common/hooks/usePagination'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'

import useSensorsTable from './useSensorsTable'
import SensorListRow from '../../molecules/SensorListRow/SensorListRow'
import { HUMIDITY_LABEL, TEMPERATURE_LABEL } from '../../../common/constants/reports'

const SensorsTable = ({ building, unitId, userType, hideUnitColumn, numRowsPerPage }) => {
  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions } =
    usePagination({ numRowsPerPage })

  const { loading, sensors, metadata, showSensorColumns, isOccupant, isSuperadmin } = useSensorsTable({
    buildingId: building?.id,
    unitId,
    page,
    setPage,
    rowsPerPage,
    userType
  })

  if (loading) return <React.Fragment></React.Fragment>
  return (
    <TableContainer>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Device Name</StyledTableCell>
            <StyledTableCell>Location</StyledTableCell>
            {!hideUnitColumn && <StyledTableCell>{unitLabel(building)}</StyledTableCell>}
            <StyledTableCell>Monitoring For</StyledTableCell>
            <StyledTableCell>Last Seen</StyledTableCell>
            {showSensorColumns.temperature && <StyledTableCell>{TEMPERATURE_LABEL}</StyledTableCell>}
            {showSensorColumns.humidity && <StyledTableCell>{HUMIDITY_LABEL}</StyledTableCell>}
            {showSensorColumns.co2 && (
              <StyledTableCell>
                CO<sub>2</sub> (ppm)
              </StyledTableCell>
            )}
            {showSensorColumns.pressure && <StyledTableCell>Pressure (hPa)</StyledTableCell>}
            {showSensorColumns.lightLevel && <StyledTableCell>Light Level</StyledTableCell>}
            {showSensorColumns.tvocMgM3 && (
              <StyledTableCell>
                TVOCs (mg/m<sup>3</sup>)
              </StyledTableCell>
            )}
            {showSensorColumns.pm10 && <StyledTableCell>PM2.5</StyledTableCell>}
            {showSensorColumns.pm25 && <StyledTableCell>PM10</StyledTableCell>}
            {showSensorColumns.isMuted && <StyledTableCell>Muted Until</StyledTableCell>}
            {!isOccupant && <StyledTableCell>Battery </StyledTableCell>}
            {!isOccupant && <StyledTableCell>In Use?</StyledTableCell>}
            {isSuperadmin && <StyledTableCell>Hardware ID</StyledTableCell>}
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sensors.map((sensor) => (
            <Fragment key={sensor.id}>
              <SensorListRow
                sensor={sensor}
                showSensorColumns={showSensorColumns}
                isOccupant={isOccupant}
                isSuperadmin={isSuperadmin}
                hideUnitColumn={hideUnitColumn}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        colSpan={4}
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={metadata?.totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default SensorsTable
