import React from 'react'
import { get } from 'lodash'

import { isCommercial } from '../../../common/utils/buildings'

import { useOccupantDashboard } from './useOccupantDashboard'

import Card from '../../molecules/Card'
import AlertsTable from '../../organisms/AlertsTable'
import SensorsTable from '../../organisms/SensorsTable'
import OccupantUnitInformationCard from '../../../components/organisms/OccupantUnitInformationCard'
import BuildingEmergencyResponseDetailsCard from '../../../components/organisms/BuildingEmergencyResponseDetailsCard'
import { NUMBER_OF_ROWS_PER_PAGE_FIVE } from '../../../common/constants/pagination'

const OccupantDashboard = () => {
  const { occupantData, occupantsUnitId, buildingData, loading } = useOccupantDashboard()

  const hideBuildingEmergencyResponseDetailsCard = loading || isCommercial(buildingData)
  const hideUnitColumn = true

  if (loading) return <React.Fragment></React.Fragment>
  return (
    <div>
      <OccupantUnitInformationCard
        occupantId={get(occupantData, ['getCurrentUser', 'id'])}
        unitId={occupantsUnitId}
      />
      <br />

      <Card title={'Alerts'}>
        {/* GraphQl scopes data for occupant's unit on the backend */}
        <AlertsTable
          building={buildingData}
          hideUnitColumn={hideUnitColumn}
          numRowsPerPage={NUMBER_OF_ROWS_PER_PAGE_FIVE}
        />
      </Card>
      <br />

      <Card title={'Sensors'}>
        <SensorsTable
          building={buildingData}
          unitId={occupantsUnitId}
          userType={get(occupantData, ['getCurrentUser', 'type'])}
          hideUnitColumn={hideUnitColumn}
          numRowsPerPage={NUMBER_OF_ROWS_PER_PAGE_FIVE}
        />
      </Card>
      <br />

      {!hideBuildingEmergencyResponseDetailsCard && (
        <BuildingEmergencyResponseDetailsCard buildingId={get(buildingData, ['id'])} />
      )}
      <br />
    </div>
  )
}

export default OccupantDashboard
