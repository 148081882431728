import React from 'react'
import { Box, FormLabel, Grid, styled } from '@mui/material'

import { unitLabel } from '../../../common/utils/buildings'
import { fullDateTimeFormat } from '../../../common/utils/dates'
import {
  getSensorDoorAlertingDaysString,
  getSensorDoorAlertingHoursString
} from '../../../common/utils/sensors'
import { SENSOR_NOT_MUTED } from '../../../common/constants/sensors'
import { HEIGHT_FULL, WIDTH_FULL } from '../../../common/constants/images'
import { disabledFormControlLabelFontColor } from '../../../common/theme/MuiFormControlLabel'

import FormCheckbox from '../../atoms/CheckmarkCell/FormCheckbox'

import SensorShowInformationRow from './SensorShowInformationRow'
import useSensorShowInformation from './useSensorShowInformation'

// Used for monitoring door's alert weekends & holidays checkbox
const FontSizeSmallTypography = styled('span')({ fontSize: 'small' })

const SensorShowInformation = ({ sensor = {}, sensorLocationPhoto = null, building = {}, unit = {} }) => {
  const { waterLabel, temperatureLabel, humidityLabel } = useSensorShowInformation({ sensor })

  return (
    <>
      {/* Sensor Information */}
      <Grid container>
        <h5 className='general-header'>Sensor Information</h5>
        <SensorShowInformationRow label={'Device Name'} value={sensor.deviceName} />
        <SensorShowInformationRow label={unitLabel(building)} value={unit.number} />
        <SensorShowInformationRow label={'Location'} value={sensor.location} />
        <SensorShowInformationRow label={'Probe Type'} value={sensor.probeType} />
        <SensorShowInformationRow label={'Additional Notes'} value={sensor.notes} />
        <SensorShowInformationRow
          label={'Mute Status'}
          value={sensor.isMuted ? fullDateTimeFormat(sensor.muteUntil) : SENSOR_NOT_MUTED}
        />
        <SensorShowInformationRow label={'Hardware ID'} value={sensor.hardwareId} />
      </Grid>
      <br />

      {/* Device Options */}
      <Grid container>
        <h5 className='general-header'>Device Options</h5>
        <Grid container direction='row' spacing={2}>
          <Grid item>
            <FormCheckbox label='In Use' checked={sensor.inUse} disabled={true} />
          </Grid>
          <Grid item>
            <FormCheckbox label='Enable Alerts' checked={sensor.enableAlerts} disabled={true} />
          </Grid>
        </Grid>
      </Grid>

      {/* Monitoring Factors */}
      <Grid container>
        <h5 className='general-header'>Monitoring Factors</h5>
        <Grid container direction='column'>
          <FormCheckbox label={waterLabel} checked={sensor.monitorWater} disabled={true} />
          <FormCheckbox label={temperatureLabel} checked={sensor.monitorTemperature} disabled={true} />
          <FormCheckbox label={humidityLabel} checked={sensor.monitorHumidity} disabled={true} />
          <FormCheckbox label='Door Open' checked={sensor.monitorDoor} disabled={true} />
          {sensor.monitorDoor && sensor.doorAlertHoursEnabled && (
            <Grid container direction='column' sx={{ ml: '42px' }}>
              <FormLabel
                disabled={true}
                sx={{ fontSize: 'small', '&.Mui-disabled': { color: disabledFormControlLabelFontColor } }}
              >
                <span>Alerting Days: {getSensorDoorAlertingDaysString(sensor)}</span>
              </FormLabel>
              <FormLabel
                disabled={true}
                sx={{ fontSize: 'small', '&.Mui-disabled': { color: disabledFormControlLabelFontColor } }}
              >
                <span>Hours: {getSensorDoorAlertingHoursString(sensor)}</span>
              </FormLabel>
              <FormCheckbox
                label={<FontSizeSmallTypography>Alert Weekends & Holidays</FontSizeSmallTypography>}
                checked={sensor.doorAlertWeekendHolidays}
                disabled={true}
                size={'small'}
                formControlLabelSx={{}} // Overrides FormCheckbox's default m: 0 and uses MUI's default styling
                checkBoxSx={{ pt: '0px', pb: '0px' }}
              />
            </Grid>
          )}
          <FormCheckbox label='Power' checked={sensor.monitorPower} disabled={true} />
          <FormCheckbox label='Cleaning Request' checked={sensor.monitorButton} disabled={true} />
        </Grid>
      </Grid>
      <br />

      {/* Installation Photo */}
      {sensorLocationPhoto && (
        <Grid container sx={{ mb: '12px' }}>
          <h5 className='general-header'>Installation Photo</h5>
          <Grid container>
            <Box height={HEIGHT_FULL / 2} width={WIDTH_FULL / 2}>
              <img src={sensorLocationPhoto} alt='Sensor Location Photo' height='100%' width='100%' />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default SensorShowInformation
