import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', getCurrentUser: { __typename?: 'User', id: string, firstName?: string | null, type: string, companyId?: string | null } };

export type DashboardForListFragment = { __typename?: 'Dashboard', id: string, name: string, token: string, company: { __typename?: 'Company', name?: string | null } };

export type FetchDashboardsQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchDashboardsQuery = { __typename?: 'Query', fetchDashboards: { __typename?: 'DashboardCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number }, collection: Array<{ __typename?: 'Dashboard', id: string, name: string, token: string, company: { __typename?: 'Company', name?: string | null } }> } };

export type FetchCompaniesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchCompaniesQuery = { __typename?: 'Query', fetchCompanies: Array<{ __typename?: 'Company', id: string, name?: string | null }> };

export type CreateDashboardMutationVariables = Types.Exact<{
  input: Types.CreateDashboardInput;
}>;


export type CreateDashboardMutation = { __typename?: 'Mutation', createDashboard: { __typename?: 'Dashboard', id: string, name: string, token: string, company: { __typename?: 'Company', name?: string | null } } };

export type UpdateDashboardMutationVariables = Types.Exact<{
  input: Types.UpdateDashboardInput;
}>;


export type UpdateDashboardMutation = { __typename?: 'Mutation', updateDashboard: { __typename?: 'Dashboard', id: string, name: string, token: string, company: { __typename?: 'Company', name?: string | null } } };

export type DeleteDashboardMutationVariables = Types.Exact<{
  input: Types.DeleteDashboardInput;
}>;


export type DeleteDashboardMutation = { __typename?: 'Mutation', deleteDashboard: { __typename?: 'Dashboard', id: string, name: string, token: string, company: { __typename?: 'Company', name?: string | null } } };

export const DashboardForListFragmentDoc = gql`
    fragment DashboardForList on Dashboard {
  id
  name
  token
  company {
    name
  }
}
    `;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  getCurrentUser {
    id
    firstName
    type
    companyId
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const FetchDashboardsDocument = gql`
    query FetchDashboards($pagination: PaginationType) {
  fetchDashboards(pagination: $pagination) {
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    collection {
      ...DashboardForList
    }
  }
}
    ${DashboardForListFragmentDoc}`;

/**
 * __useFetchDashboardsQuery__
 *
 * To run a query within a React component, call `useFetchDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDashboardsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchDashboardsQuery(baseOptions?: Apollo.QueryHookOptions<FetchDashboardsQuery, FetchDashboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDashboardsQuery, FetchDashboardsQueryVariables>(FetchDashboardsDocument, options);
      }
export function useFetchDashboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDashboardsQuery, FetchDashboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDashboardsQuery, FetchDashboardsQueryVariables>(FetchDashboardsDocument, options);
        }
export type FetchDashboardsQueryHookResult = ReturnType<typeof useFetchDashboardsQuery>;
export type FetchDashboardsLazyQueryHookResult = ReturnType<typeof useFetchDashboardsLazyQuery>;
export type FetchDashboardsQueryResult = Apollo.QueryResult<FetchDashboardsQuery, FetchDashboardsQueryVariables>;
export const FetchCompaniesDocument = gql`
    query FetchCompanies {
  fetchCompanies {
    id
    name
  }
}
    `;

/**
 * __useFetchCompaniesQuery__
 *
 * To run a query within a React component, call `useFetchCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<FetchCompaniesQuery, FetchCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCompaniesQuery, FetchCompaniesQueryVariables>(FetchCompaniesDocument, options);
      }
export function useFetchCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCompaniesQuery, FetchCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCompaniesQuery, FetchCompaniesQueryVariables>(FetchCompaniesDocument, options);
        }
export type FetchCompaniesQueryHookResult = ReturnType<typeof useFetchCompaniesQuery>;
export type FetchCompaniesLazyQueryHookResult = ReturnType<typeof useFetchCompaniesLazyQuery>;
export type FetchCompaniesQueryResult = Apollo.QueryResult<FetchCompaniesQuery, FetchCompaniesQueryVariables>;
export const CreateDashboardDocument = gql`
    mutation CreateDashboard($input: CreateDashboardInput!) {
  createDashboard(input: $input) {
    ...DashboardForList
  }
}
    ${DashboardForListFragmentDoc}`;
export type CreateDashboardMutationFn = Apollo.MutationFunction<CreateDashboardMutation, CreateDashboardMutationVariables>;

/**
 * __useCreateDashboardMutation__
 *
 * To run a mutation, you first call `useCreateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardMutation, { data, loading, error }] = useCreateDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDashboardMutation(baseOptions?: Apollo.MutationHookOptions<CreateDashboardMutation, CreateDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDashboardMutation, CreateDashboardMutationVariables>(CreateDashboardDocument, options);
      }
export type CreateDashboardMutationHookResult = ReturnType<typeof useCreateDashboardMutation>;
export type CreateDashboardMutationResult = Apollo.MutationResult<CreateDashboardMutation>;
export type CreateDashboardMutationOptions = Apollo.BaseMutationOptions<CreateDashboardMutation, CreateDashboardMutationVariables>;
export const UpdateDashboardDocument = gql`
    mutation UpdateDashboard($input: UpdateDashboardInput!) {
  updateDashboard(input: $input) {
    ...DashboardForList
  }
}
    ${DashboardForListFragmentDoc}`;
export type UpdateDashboardMutationFn = Apollo.MutationFunction<UpdateDashboardMutation, UpdateDashboardMutationVariables>;

/**
 * __useUpdateDashboardMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardMutation, { data, loading, error }] = useUpdateDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDashboardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDashboardMutation, UpdateDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDashboardMutation, UpdateDashboardMutationVariables>(UpdateDashboardDocument, options);
      }
export type UpdateDashboardMutationHookResult = ReturnType<typeof useUpdateDashboardMutation>;
export type UpdateDashboardMutationResult = Apollo.MutationResult<UpdateDashboardMutation>;
export type UpdateDashboardMutationOptions = Apollo.BaseMutationOptions<UpdateDashboardMutation, UpdateDashboardMutationVariables>;
export const DeleteDashboardDocument = gql`
    mutation DeleteDashboard($input: DeleteDashboardInput!) {
  deleteDashboard(input: $input) {
    ...DashboardForList
  }
}
    ${DashboardForListFragmentDoc}`;
export type DeleteDashboardMutationFn = Apollo.MutationFunction<DeleteDashboardMutation, DeleteDashboardMutationVariables>;

/**
 * __useDeleteDashboardMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardMutation, { data, loading, error }] = useDeleteDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDashboardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDashboardMutation, DeleteDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDashboardMutation, DeleteDashboardMutationVariables>(DeleteDashboardDocument, options);
      }
export type DeleteDashboardMutationHookResult = ReturnType<typeof useDeleteDashboardMutation>;
export type DeleteDashboardMutationResult = Apollo.MutationResult<DeleteDashboardMutation>;
export type DeleteDashboardMutationOptions = Apollo.BaseMutationOptions<DeleteDashboardMutation, DeleteDashboardMutationVariables>;