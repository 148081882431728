import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSensorsTableQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.PaginationType>;
  buildingId?: Types.InputMaybe<Types.Scalars['ID']>;
  unitId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type FetchSensorsTableQuery = { __typename?: 'Query', fetchSensors: { __typename?: 'SensorCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number }, collection: Array<{ __typename?: 'Sensor', id: string, deviceName?: string | null, unitId?: string | null, location?: string | null, monitoringTypesDisplay?: string | null, muteUntil?: unknown | null, isMuted?: boolean | null, inUse: boolean, hardwareId?: string | null, unit?: { __typename?: 'Unit', number?: string | null } | null, sensorRecentEvent?: { __typename?: 'SensorRecentEvent', temperature?: number | null, humidity?: number | null, co2?: number | null, pressure?: number | null, lightLevel?: number | null, tvocMgM3?: number | null, pm25?: number | null, pm10?: number | null, battery?: number | null, batteryPercentage?: number | null, updatedAt: unknown } | null }> } };


export const FetchSensorsTableDocument = gql`
    query FetchSensorsTable($pagination: PaginationType, $buildingId: ID, $unitId: ID) {
  fetchSensors(pagination: $pagination, buildingId: $buildingId, unitId: $unitId) {
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    collection {
      id
      deviceName
      unitId
      location
      monitoringTypesDisplay
      muteUntil
      isMuted
      inUse
      hardwareId
      unit {
        number
      }
      sensorRecentEvent {
        temperature
        humidity
        co2
        pressure
        lightLevel
        tvocMgM3
        pm25
        pm10
        battery
        batteryPercentage
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useFetchSensorsTableQuery__
 *
 * To run a query within a React component, call `useFetchSensorsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSensorsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSensorsTableQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      buildingId: // value for 'buildingId'
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useFetchSensorsTableQuery(baseOptions?: Apollo.QueryHookOptions<FetchSensorsTableQuery, FetchSensorsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSensorsTableQuery, FetchSensorsTableQueryVariables>(FetchSensorsTableDocument, options);
      }
export function useFetchSensorsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSensorsTableQuery, FetchSensorsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSensorsTableQuery, FetchSensorsTableQueryVariables>(FetchSensorsTableDocument, options);
        }
export type FetchSensorsTableQueryHookResult = ReturnType<typeof useFetchSensorsTableQuery>;
export type FetchSensorsTableLazyQueryHookResult = ReturnType<typeof useFetchSensorsTableLazyQuery>;
export type FetchSensorsTableQueryResult = Apollo.QueryResult<FetchSensorsTableQuery, FetchSensorsTableQueryVariables>;