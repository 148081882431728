import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSensorsPageQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetSensorsPageQuery = { __typename?: 'Query', getSensor: { __typename?: 'Sensor', id: string, deviceName?: string | null, location?: string | null, notes?: string | null, hardwareId?: string | null, probeType?: string | null, inUse: boolean, isMuted?: boolean | null, muteUntil?: unknown | null, enableAlerts: boolean, monitoringTypes?: Array<string> | null, monitorButton: boolean, monitorDoor: boolean, monitorHumidity: boolean, monitorPower: boolean, monitorTemperature: boolean, monitorWater: boolean, waterSensitivity?: number | null, highTemperatureThreshold?: number | null, lowTemperatureThreshold?: number | null, highHumidityThreshold?: number | null, lowHumidityThreshold?: number | null, doorAlertDays?: string | null, doorAlertHoursEnabled: boolean, doorAlertStartTime?: string | null, doorAlertEndTime?: string | null, doorAlertWeekendHolidays?: boolean | null, building?: { __typename?: 'Building', id: string, buildingType: string } | null, unit?: { __typename?: 'Unit', id: string, number?: string | null } | null } };

export type GetSensorsPageLocationPhotoQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetSensorsPageLocationPhotoQuery = { __typename?: 'Query', getSensor: { __typename?: 'Sensor', id: string, sensorLocationPhotoFull?: string | null } };

export type GetCurrentUserForSensorsPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentUserForSensorsPageQuery = { __typename?: 'Query', getCurrentUser: { __typename?: 'User', id: string, type: string } };


export const GetSensorsPageDocument = gql`
    query GetSensorsPage($id: ID!) {
  getSensor(id: $id) {
    id
    deviceName
    location
    notes
    hardwareId
    probeType
    inUse
    isMuted
    muteUntil
    enableAlerts
    monitoringTypes
    monitorButton
    monitorDoor
    monitorHumidity
    monitorPower
    monitorTemperature
    monitorWater
    waterSensitivity
    highTemperatureThreshold
    lowTemperatureThreshold
    highHumidityThreshold
    lowHumidityThreshold
    doorAlertDays
    doorAlertHoursEnabled
    doorAlertStartTime
    doorAlertEndTime
    doorAlertWeekendHolidays
    building {
      id
      buildingType
    }
    unit {
      id
      number
    }
  }
}
    `;

/**
 * __useGetSensorsPageQuery__
 *
 * To run a query within a React component, call `useGetSensorsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSensorsPageQuery(baseOptions: Apollo.QueryHookOptions<GetSensorsPageQuery, GetSensorsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSensorsPageQuery, GetSensorsPageQueryVariables>(GetSensorsPageDocument, options);
      }
export function useGetSensorsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSensorsPageQuery, GetSensorsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSensorsPageQuery, GetSensorsPageQueryVariables>(GetSensorsPageDocument, options);
        }
export type GetSensorsPageQueryHookResult = ReturnType<typeof useGetSensorsPageQuery>;
export type GetSensorsPageLazyQueryHookResult = ReturnType<typeof useGetSensorsPageLazyQuery>;
export type GetSensorsPageQueryResult = Apollo.QueryResult<GetSensorsPageQuery, GetSensorsPageQueryVariables>;
export const GetSensorsPageLocationPhotoDocument = gql`
    query GetSensorsPageLocationPhoto($id: ID!) {
  getSensor(id: $id) {
    id
    sensorLocationPhotoFull
  }
}
    `;

/**
 * __useGetSensorsPageLocationPhotoQuery__
 *
 * To run a query within a React component, call `useGetSensorsPageLocationPhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorsPageLocationPhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorsPageLocationPhotoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSensorsPageLocationPhotoQuery(baseOptions: Apollo.QueryHookOptions<GetSensorsPageLocationPhotoQuery, GetSensorsPageLocationPhotoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSensorsPageLocationPhotoQuery, GetSensorsPageLocationPhotoQueryVariables>(GetSensorsPageLocationPhotoDocument, options);
      }
export function useGetSensorsPageLocationPhotoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSensorsPageLocationPhotoQuery, GetSensorsPageLocationPhotoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSensorsPageLocationPhotoQuery, GetSensorsPageLocationPhotoQueryVariables>(GetSensorsPageLocationPhotoDocument, options);
        }
export type GetSensorsPageLocationPhotoQueryHookResult = ReturnType<typeof useGetSensorsPageLocationPhotoQuery>;
export type GetSensorsPageLocationPhotoLazyQueryHookResult = ReturnType<typeof useGetSensorsPageLocationPhotoLazyQuery>;
export type GetSensorsPageLocationPhotoQueryResult = Apollo.QueryResult<GetSensorsPageLocationPhotoQuery, GetSensorsPageLocationPhotoQueryVariables>;
export const GetCurrentUserForSensorsPageDocument = gql`
    query GetCurrentUserForSensorsPage {
  getCurrentUser {
    id
    type
  }
}
    `;

/**
 * __useGetCurrentUserForSensorsPageQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserForSensorsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserForSensorsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserForSensorsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserForSensorsPageQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserForSensorsPageQuery, GetCurrentUserForSensorsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserForSensorsPageQuery, GetCurrentUserForSensorsPageQueryVariables>(GetCurrentUserForSensorsPageDocument, options);
      }
export function useGetCurrentUserForSensorsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserForSensorsPageQuery, GetCurrentUserForSensorsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserForSensorsPageQuery, GetCurrentUserForSensorsPageQueryVariables>(GetCurrentUserForSensorsPageDocument, options);
        }
export type GetCurrentUserForSensorsPageQueryHookResult = ReturnType<typeof useGetCurrentUserForSensorsPageQuery>;
export type GetCurrentUserForSensorsPageLazyQueryHookResult = ReturnType<typeof useGetCurrentUserForSensorsPageLazyQuery>;
export type GetCurrentUserForSensorsPageQueryResult = Apollo.QueryResult<GetCurrentUserForSensorsPageQuery, GetCurrentUserForSensorsPageQueryVariables>;