import React from 'react'
import { get, capitalize } from 'lodash'
import TableCell from '@mui/material/TableCell'

import { fullDateTimeFormat } from '../../../common/utils/dates'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'

import CheckmarkCell from '../../atoms/Cells/CheckmarkCell'

const SensorEventListRow = ({ sensorEvent, hasTemperature, hasPowerEvent, isSuperadmin }) => {
  const sensor = get(sensorEvent, ['sensor'], {})

  return (
    <StyledTableRow hover>
      <TableCell align='left'>{sensor.unit?.number}</TableCell>
      <TableCell align='left'>{sensor.location}</TableCell>
      <TableCell align='left'>{sensorEvent.displayEventType}</TableCell>
      <TableCell align='left'>{fullDateTimeFormat(sensorEvent.eventTime)}</TableCell>
      {hasTemperature && <TableCell align='left'>{sensorEvent.temperature}</TableCell>}
      {hasPowerEvent && <TableCell align='left'>{capitalize(sensorEvent.powerSource)}</TableCell>}
      <CheckmarkCell booleanValue={sensorEvent.generatedAlert} />
      <CheckmarkCell booleanValue={sensorEvent.sensorMuted} />
      {isSuperadmin && <TableCell align='left'>{sensor.hardwareId}</TableCell>}
      {isSuperadmin && <TableCell align='left'>{sensorEvent.messageId}</TableCell>}
    </StyledTableRow>
  )
}

export default SensorEventListRow
