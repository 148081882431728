import React, { useState } from 'react'
import {
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Box,
  InputLabel,
  Typography
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useCreateDashboardMutation, useFetchCompaniesQuery } from '../gql.generated'

const schema = yup
  .object({
    name: yup.string().required('Name is a required field'),
    companyId: yup.string().required('Name is a required field')
  })
  .required()

export default function CreateDashboardDialog() {
  const [open, setOpen] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      companyId: ''
    }
  })

  const [createDashboard] = useCreateDashboardMutation()
  const { data, loading } = useFetchCompaniesQuery({ skip: !open })
  const companies = data?.fetchCompanies || []

  const onSubmit = (input) => {
    createDashboard({
      variables: {
        input
      },
      refetchQueries: ['FetchDashboards']
    }).then(handleClose)
  }

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    reset({
      name: '',
      companyId: ''
    })
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h6'> Dashboard Manager </Typography>
        <Button variant='contained' onClick={handleClickOpen}>
          Create Dashboard
        </Button>
      </Box>

      <Dialog
        open={open}
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <DialogTitle>Create Dashboard</DialogTitle>
          <DialogContent>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  margin='normal'
                  label='Name'
                  autoFocus
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                />
              )}
            />

            <Controller
              name='companyId'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel error={Boolean(errors.companyId)} required id='since-label'>
                    Company
                  </InputLabel>
                  <Select
                    {...field}
                    required
                    fullWidth
                    label='Company'
                    // placeholder='Company *'
                    disabled={loading}
                    error={Boolean(errors.companyId)}
                  >
                    {companies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleClose}>
              Close
            </Button>
            <Button variant='contained' type='submit'>
              Create
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}
