import React from 'react'

import WaterTempResponse from './WaterTempResponse'
import SeveritySelector from './SeveritySelector'
import { Box } from '@mui/material'

interface Props {
  Acknowledge: boolean
  IsWaterTempHumPwrAlert: boolean
  buildingName: string
  waterEmergencyResponse: string
  doorEmergencyResponse: string
  acknowledgedUserName: any
  severity: string
  feedback: string
  PropMgmtData: any[]
  onSubmit: (severity: string, feedback: string) => void
}

const EmergencyResponse = ({
  Acknowledge,
  IsWaterTempHumPwrAlert,
  buildingName,
  waterEmergencyResponse,
  doorEmergencyResponse,
  acknowledgedUserName,
  severity,
  feedback,
  PropMgmtData,
  onSubmit
}: Props) => {
  return (
    <>
      <Box className='alert-body'>
        <Box className='alert-main'>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <img
                    width='200px'
                    src='https://cirkuit.io/wp-content/uploads/2023/11/cropped-1-e1699565618476.png'
                    alt='cirkuit-logo'
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          {Acknowledge && <h2 className='ack-header'>Alert Acknowledged</h2>}
          <br />
          {buildingName != '' && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <SeveritySelector
                acknowledgedUserName={acknowledgedUserName}
                initialSeverity={severity}
                onSubmit={onSubmit}
                feedback={feedback}
              />
            </div>
          )}
          <br />
          <h3 className='center-text' style={{ padding: '10px' }}>
            {buildingName} {IsWaterTempHumPwrAlert ? 'Emergency ' : 'Door Access'} Response Details
          </h3>
          <Box className='emergency-response-box'>
            {IsWaterTempHumPwrAlert ? (
              <WaterTempResponse
                buildingName={buildingName}
                waterEmergencyResponse={waterEmergencyResponse}
                PropMgmtData={PropMgmtData}
              />
            ) : (
              <p
                className='padding-container'
                dangerouslySetInnerHTML={{ __html: doorEmergencyResponse }}
              ></p>
            )}
          </Box>
          <br />
          <table style={{ width: '100%', marginBottom: '2%' }}>
            <tbody>
              <tr>
                <td className='ack-header'>2024 Copyright © cirkuit Inc</td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
    </>
  )
}

export default EmergencyResponse
