import React, { Fragment, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableCell,
  CircularProgress
} from '@mui/material'
import { get, isEmpty } from 'lodash'

import usePagination from '../../../common/hooks/usePagination'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'

import { useFetchUserReportsQuery } from './GraphQl/gql.generated'
import UserReportListRow from '../../molecules/UserReportListRow'
import { useUpdateUserReportMutation } from '../../pages/UserReportsPage/GraphQl/gql.generated'

const UserReportsTable = ({ numRowsPerPage, showNoRecordsMessage = true }) => {
  const [userReports, setUserReports] = useState([])
  const [metadata, setMetaData] = useState({})

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions } = usePagination({
    numRowsPerPage
  })
  const { data, loading, error } = useFetchUserReportsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      pagination: {
        page: page + 1, // TablePagination starts count pages from zero / 0
        per: rowsPerPage
      }
    }
  })

  const [updateUserReport] = useUpdateUserReportMutation()
  const toggleSending = async (userReport) => {
    updateUserReport({
      variables: {
        input: {
          id: userReport.id,
          active: !userReport.active
        }
      },
      onCompleted: (data) =>
        setUserReports(
          userReports.map((existingUserReport) =>
            existingUserReport.id === userReport.id
              ? { ...existingUserReport, active: !existingUserReport.active }
              : existingUserReport
          )
        )
    })
  }

  useEffect(() => {
    setUserReports(get(data, ['fetchUserReports', 'collection'], []))
    setMetaData(get(data, ['fetchUserReports', 'metadata'], {}))
  }, [data])

  return (
    <TableContainer>
      <Table aria-label='collapsible table' size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Send Frequency</StyledTableCell>
            <StyledTableCell>Reporting Start Date</StyledTableCell>
            <StyledTableCell>Report Metrics</StyledTableCell>
            <StyledTableCell>Last Run</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {showNoRecordsMessage && isEmpty(userReports) && (
            <StyledTableRow hover>
              <TableCell align='center' sx={{ verticalAlign: 'center', p: 4 }} colSpan={7}>
                {loading ? <CircularProgress /> : <h4>No Saved Reports</h4>}
              </TableCell>
            </StyledTableRow>
          )}

          {userReports.map((userReport) => (
            <Fragment key={userReport.id}>
              <UserReportListRow userReport={userReport} toggleSending={() => toggleSending(userReport)} />
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        colSpan={4}
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={metadata?.totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={isEmpty(metadata) ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default UserReportsTable
